import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useUserProvider } from '../provider/UserProvider';
import apiClient from '../services/apiClient';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useNotification } from '../provider/NotificationProvider';
import { LoadingFullScreen } from '../components/Loading';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const CreateProfile: React.FC = () => {
    const [isCreatingProfile, setIsCreatingProfile] = useState(false);
    const [currentLabel, setCurrentLabel] = useState(0);
    const { authToken_access, profile, setProfile } = useUserProvider();
    const navigate = useNavigate();
    const { addNotification } = useNotification();

    // Validation Schema
    const validationSchema = Yup.object().shape({
        display_name: Yup.string()
            .max(50, 'Le pseudo doit contenir au maximum 50 caractères'),
        birthdate: Yup.date()
            .required('Veuillez entrer votre date de naissance')
            .max(new Date('2023-01-01'), 'La date de naissance est trop recente')
            .min(new Date('1910-01-01'), 'La date de naissance est trop ancienne'),
        weight: Yup.number()
            .required('Veuillez entrer votre poids')
            .min(10, 'Le poids doit être supérieur à 10 kg')
            .max(500, 'Le poids doit être inférieur à 500 kg'),
        height: Yup.number()
            .required('Veuillez entrer votre taille')
            .min(30, 'La taille doit être supérieure à 50 cm')
            .max(300, 'La taille doit être inférieure à 250 cm'),
        gender: Yup.string()
            .required('Veuillez sélectionner votre sexe')
            .oneOf(['male', 'female'], 'Valeur invalide pour le sexe'),
        activity_level: Yup.string()
            .required('Veuillez sélectionner votre activité physique')
            .oneOf(
                ['sedentary', 'light', 'moderate', 'very_active', 'extremely_active'],
                'Valeur invalide pour le niveau d’activité'
            ),
        weight_goal: Yup.number()
            .required('Veuillez entrer votre objectif de poids')
            .min(10, 'Le poids cible doit être supérieur à 10 kg')
            .max(500, 'Le poids cible doit être inférieur à 500 kg'),
        gain_per_week: Yup.number()
            .test(
                'required-gain-per-week',
                'Veuillez sélectionner votre gain par mois',
                function (value) {
                    const { weight, weight_goal } = this.parent;
                    if (weight && weight_goal && weight !== weight_goal) {
                        return value !== undefined;
                    }
                    return true; // No need to validate if weight and weight_goal are equal
                }
            )
            .oneOf(
                [-1, -0.75, -0.5, -0.25, 0.25, 0.5, 0.75, 1],
                'Valeur invalide pour le gain par mois'
            ),
    });

    const formik = useFormik({
        initialValues: {
            display_name: profile?.display_name || '',
            birthdate: profile?.birthdate || '',
            weight: profile?.weight || '',
            height: profile?.height || '',
            gender: profile?.gender || '',
            activity_level: profile?.activity_level || '',
            weight_goal: profile?.weight_goal || '',
            gain_per_week: profile?.gain_per_week || '',
        },
        validationSchema,
        onSubmit: (values) => {
            setIsCreatingProfile(true);
            if (profile.user_created) {
                apiClient
                    .put('/api/profile/', values, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken_access}`,
                        },
                    })
                    .then((response) => {
                        if (response) {
                            setProfile({
                                ...profile,
                                display_name: values.display_name,
                                birthdate: values.birthdate,
                                weight: Number(values.weight),
                                height: Number(values.height),
                                weight_goal: Number(values.weight_goal),
                                activity_level: values.activity_level,
                                gain_per_week: Number(values.gain_per_week),
                                meal_schedule: '4'
                            })
                            addNotification('success', 'Bienvenue sur Nutrimmy!');
                            navigate('/dashboard/profile'); // Navigate to another page after success
                        }
                    })
                    .catch((error) => {
                        console.error('Error updating profile:', error);
                    }).finally(() => {
                        setIsCreatingProfile(false);
                    })
            } else {
                setProfile({
                    ...profile,
                    display_name: values.display_name,
                    birthdate: values.birthdate,
                    weight: Number(values.weight),
                    height: Number(values.height),
                    weight_goal: Number(values.weight_goal),
                    activity_level: values.activity_level,
                    gain_per_week: Number(values.gain_per_week),
                });
                navigate('/login/create-profile/save');
                setIsCreatingProfile(false);
            }
        },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        formik.handleChange(event);
        console.log(formik.errors);



    }

    useEffect(() => {
        apiClient.get('/api/profile/check',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken_access}`,
                },
            }
        ).then((response) => {
            if (!response.data.user_created) {
                addNotification('warning', 'Vous n\'avez pas encore de compte. Veuillez vous inscrire pour continuer.', 7000);
                navigate('/login');
            }
        })
    }, [profile.user_created]);

    if (isCreatingProfile) {
        return <LoadingFullScreen text="Création de votre profil..." />
    }

    return (
        <div className='min-h-screen p-4 pt-16 md:p-16 relative'
            style={
                {
                    backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url(${process.env.PUBLIC_URL + '/woman-eating.jpg'})`,
                    backgroundSize: '100% 60vh, auto 60vh',
                    backgroundPosition: '100% 120%, 70% 120% ',
                    backgroundRepeat: 'no-repeat',
                }
            }
        >
            <div
                className="absolute top-12 left-4 p-1 rounded-full pr-3 flex items-center uppercase text-xs gap-2 cursor-pointer"
                onClick={() => { if (currentLabel > 0) { setCurrentLabel(currentLabel - 1) } else { navigate('/login') } }}
            >
                <div className='p-1 bg-white rounded-full'>
                    <ArrowLeftIcon className="h-4 w-4 text-black" />
                </div>
                {/* Retour */}
            </div>

            <div className='w-full absolute top-8 left-0 px-4 flex items-center gap-1'>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel >= 0 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 0 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 1 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 2 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 3 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 4 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 5 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 6 ? ' bg-purple-500' : ' bg-purple-100')}></div>
                <div className={'w-full h-1 rounded-full transition ' + (currentLabel > 7 ? ' bg-purple-500' : ' bg-purple-100')}></div>

            </div>

            <form
                onSubmit={formik.handleSubmit}
                className="form flex flex-col justify-start items-center w-full md:w-[400px]"
            >
                <div className={'w-full pt-16' + (currentLabel === 0 ? '' : ' hidden')}>
                    <label className="block mb-4 text-xl md:text-2xl font-semibold text-gray-800">
                        Hmm... <br />On dirait que tu n'as pas encore de compte, comment dois-je t'appeler?
                    </label>
                    <input
                        type="text"
                        name="display_name"
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.display_name}
                        placeholder="Votre pseudo ou prénom"
                        className="p-2 border-b border-rose-300 w-full"
                    />
                    {formik.touched.display_name && formik.errors.display_name && (
                        <p className="text-red-500">{formik.errors.display_name}</p>
                    )}
                    {
                        !formik.errors.display_name &&
                        <div
                            className="mt-8 relative w-full flex justify-center items-center bg-green-600 p-2 rounded-full pl-3 flex items-center gap-2 cursor-pointer text-white"
                            onClick={() => { setCurrentLabel(1) }}
                        >
                            Etape suivante
                            <div className='p-1 bg-white rounded-full absolute right-1'>
                                <ArrowRightIcon className="h-6 w-6 text-green-500" />
                            </div>
                        </div>
                    }
                </div>

                <div className={'w-full pt-16' + (currentLabel === 1 ? '' : ' hidden')}>
                    <label className="block text-xl md:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Votre date de naissance
                    </label>
                    <input
                        type="date"
                        name="birthdate"
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.birthdate}
                        className="mb-2 p-2 border-b border-rose-300 w-full"
                    />
                    {formik.errors.birthdate && (
                        <p className="text-red-500">{formik.errors.birthdate}</p>
                    )}
                    {
                        !formik.errors.birthdate && formik.values.birthdate &&
                        <div
                            className="mt-8 relative w-full flex justify-center items-center bg-green-600 p-2 rounded-full pl-3 flex items-center gap-2 cursor-pointer text-white"
                            onClick={() => { setCurrentLabel(2) }}
                        >
                            Etape suivante
                            <div className='p-1 bg-white rounded-full absolute right-1'>
                                <ArrowRightIcon className="h-6 w-6 text-green-500" />
                            </div>
                        </div>
                    }
                </div>



                <div className={'w-full pt-16' + (currentLabel === 2 ? '' : ' hidden')}>
                    <label className="block text-xl md:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Votre poids actuel
                    </label>
                    <input
                        type="number"
                        name="weight"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.weight}
                        placeholder="Votre poids en kg"
                        className="mb-2 p-2 border-b border-rose-300 w-full"
                    />
                    {formik.touched.weight && formik.errors.weight && (
                        <p className="text-red-500">{formik.errors.weight}</p>
                    )}
                    {
                        !formik.errors.weight && formik.values.weight &&
                        <div
                            className="mt-8 relative w-full flex justify-center items-center bg-green-600 p-2 rounded-full pl-3 flex items-center gap-2 cursor-pointer text-white"
                            onClick={() => { setCurrentLabel(3) }}
                        >
                            Etape suivante
                            <div className='p-1 bg-white rounded-full absolute right-1'>
                                <ArrowRightIcon className="h-6 w-6 text-green-500" />
                            </div>
                        </div>
                    }
                </div>


                <div className={'w-full pt-16' + (currentLabel === 3 ? '' : ' hidden')}>
                    <label className="block text-xl md:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Votre taille
                    </label>
                    <input
                        type="number"
                        name="height"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.height}
                        placeholder="Votre taille en cm"
                        className="mb-2 p-2 border-b border-rose-300 w-full"
                    />
                    {formik.touched.height && formik.errors.height && (
                        <p className="text-red-500">{formik.errors.height}</p>
                    )}
                    {
                        !formik.errors.height && formik.values.height &&
                        <div
                            className="mt-8 relative w-full flex justify-center items-center bg-green-600 p-2 rounded-full pl-3 flex items-center gap-2 cursor-pointer text-white"
                            onClick={() => { setCurrentLabel(4) }}
                        >
                            Etape suivante
                            <div className='p-1 bg-white rounded-full absolute right-1'>
                                <ArrowRightIcon className="h-6 w-6 text-green-500" />
                            </div>
                        </div>
                    }
                </div>




                <div className={'w-full pt-16' + (currentLabel === 4 ? '' : ' hidden')}>
                    <label className="block text-xl md:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Votre sexe
                    </label>
                    <select
                        name="gender"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.gender}
                        className="mb-2 p-2 border-b border-rose-300 w-full"
                    >
                        <option value="">Sélectionnez votre sexe</option>
                        <option value="male">Homme</option>
                        <option value="female">Femme</option>
                    </select>
                    {formik.touched.gender && formik.errors.gender && (
                        <p className="text-red-500">{formik.errors.gender}</p>
                    )}
                    {
                        !formik.errors.gender && formik.values.gender &&
                        <div
                            className="mt-8 relative w-full flex justify-center items-center bg-green-600 p-2 rounded-full pl-3 flex items-center gap-2 cursor-pointer text-white"
                            onClick={() => { setCurrentLabel(5) }}
                        >
                            Etape suivante
                            <div className='p-1 bg-white rounded-full absolute right-1'>
                                <ArrowRightIcon className="h-6 w-6 text-green-500" />
                            </div>
                        </div>
                    }
                </div>





                <div className={'w-full pt-16' + (currentLabel === 5 ? '' : ' hidden')}>
                    <label className="block text-xl md:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Votre activité physique
                    </label>
                    <select
                        name="activity_level"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.activity_level}
                        className="mb-2 p-2 border-b border-rose-300 w-full"
                    >
                        <option value="">Sélectionnez votre niveau d'activité physique</option>
                        <option value="sedentary">Très peu actif (travail de bureau, peu ou pas de sport)</option>
                        <option value="light">Légèrement actif (marche quotidienne, peu de sport)</option>
                        <option value="moderate">Modéramment actif (activités régulières, 1-3 séances de sport/semaine)</option>
                        <option value="very_active">Très actif (travail physique ou 4-5 séances de sport/semaine)</option>
                        <option value="extremely_active">Extrêmement actif (sport intense ou travail très physique)</option>
                    </select>
                    {formik.touched.activity_level && formik.errors.activity_level && (
                        <p className="text-red-500">{formik.errors.activity_level}</p>
                    )}
                    {
                        !formik.errors.activity_level && formik.values.activity_level &&
                        <div
                            className="mt-8 relative w-full flex justify-center items-center bg-green-600 p-2 rounded-full pl-3 flex items-center gap-2 cursor-pointer text-white"
                            onClick={() => { setCurrentLabel(6) }}
                        >
                            Etape suivante
                            <div className='p-1 bg-white rounded-full absolute right-1'>
                                <ArrowRightIcon className="h-6 w-6 text-green-500" />
                            </div>
                        </div>
                    }
                </div>






                <div className={'w-full pt-16' + (currentLabel === 6 ? '' : ' hidden')}>
                    <label className="block text-xl md:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                        Votre objectif de poids
                    </label>
                    <input
                        type="number"
                        name="weight_goal"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.weight_goal}
                        placeholder="Votre objectif de poids en kg"
                        className="mb-2 p-2 border-b border-rose-300 w-full"
                    />
                    {formik.touched.weight_goal && formik.errors.weight_goal && (
                        <p className="text-red-500">{formik.errors.weight_goal}</p>
                    )}
                    {
                        !formik.errors.weight_goal && formik.values.weight_goal &&
                        <div
                            className="mt-8 relative w-full flex justify-center items-center bg-green-600 p-2 rounded-full pl-3 flex items-center gap-2 cursor-pointer text-white"
                            onClick={() => { setCurrentLabel(7) }}
                        >
                            Etape suivante
                            <div className='p-1 bg-white rounded-full absolute right-1'>
                                <ArrowRightIcon className="h-6 w-6 text-green-500" />
                            </div>
                        </div>
                    }
                </div>


                <div className={'w-full pt-16' + (currentLabel === 7 ? '' : ' hidden')}>
                    {formik.values.weight_goal && formik.values.weight !== formik.values.weight_goal && (
                        <>
                            <label className="block text-xl md:text-2xl font-semibold text-gray-800 mb-4 mt-8">
                                Votre {formik.values.weight < formik.values.weight_goal ? 'gain' : 'perte'} par mois
                            </label>
                            <select
                                name="gain_per_week"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.gain_per_week}
                                className="mb-2 p-2 border-b border-rose-300 w-full"
                            >
                                {formik.values.weight < formik.values.weight_goal ? (
                                    <>
                                        <option value="">Sélectionnez votre gain par mois</option>
                                        <option value="0.25">1 kg / mois</option>
                                        <option value="0.5">2 kg / mois</option>
                                        <option value="0.75">3 kg / mois</option>
                                        <option value="1">4 kg / mois</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="">Sélectionnez votre perte par mois</option>
                                        <option value="-0.25">-1 kg / mois</option>
                                        <option value="-0.5">-2 kg / mois</option>
                                        <option value="-0.75">-3 kg / mois</option>
                                        <option value="-1">-4 kg / mois</option>
                                    </>
                                )}
                            </select>
                            {formik.touched.gain_per_week && formik.errors.gain_per_week && (
                                <p className="text-red-500">{formik.errors.gain_per_week}</p>
                            )}
                            {
                                !formik.errors.gain_per_week && formik.values.gain_per_week &&
                                <button
                                    type="submit"
                                    className="w-full bg-black flex items-center gap-2 justify-center transition duration-200 text-white py-2 px-4 mt-8 rounded-full"
                                >
                                    Obtenir mon planning maintenant
                                    {/* <ArrowRightIcon className="h-4 w-4" /> */}
                                </button>
                            }
                        </>
                    )}
                </div>





                {formik.errors && Object.keys(formik.errors).length > 0 && formik.touched && Object.keys(formik.touched).length > 0 && (
                    <p className="text-red-500 text-sm mt-4 hidden">
                        Il y a des erreurs dans les champs que vous avez saisis. Veuillez vérifier les champs en rouge.
                    </p>
                )}
            </form>
        </div>
    );
};

export default CreateProfile;