import React, { useState, useEffect } from 'react';
import { Ingredient } from '../types/recipeType';
import { recalculateIngredientNutrimentsQuantity } from '../services/diet';
import Cookies from 'js-cookie';
import TooltipComponent from './Tooltip';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'
import apiClient from '../services/apiClient';
import { SimpleLoading } from './Loading';
import { Bars3CenterLeftIcon, InformationCircleIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { FaMagnifyingGlass, FaRegFaceSadCry } from 'react-icons/fa6';
import { AiOutlineSafetyCertificate } from "react-icons/ai";

// To parse an ingredient from the API Open Food Facts
// Use only for the first time (thats why its set to 100)
const ingredientParser = (ingredientAPI: any): Ingredient => {
    return {
        fdc_id: ingredientAPI.fdc_id,
        name: ingredientAPI.description,
        data_type: ingredientAPI.data_type,
        nutriments_100g: {
            calories: Math.round(ingredientAPI.nutrients.energy?.amount),
            proteins: Math.round(ingredientAPI.nutrients.protein?.amount),
            fats: Math.round(ingredientAPI.nutrients.fat?.amount),
            carbohydrates: Math.round(ingredientAPI.nutrients.carbohydrate?.amount)
        },
        nutriments: {
            calories: Math.round(ingredientAPI.nutrients.energy?.amount),
            proteins: Math.round(ingredientAPI.nutrients.protein?.amount),
            fats: Math.round(ingredientAPI.nutrients.fat?.amount),
            carbohydrates: Math.round(ingredientAPI.nutrients.carbohydrate?.amount)
        },
        quantity: ingredientAPI.portion.length > 0 ? (ingredientAPI.portion[0].amount * ingredientAPI.portion[0].gram_weight) : 100,
        unit: "grammes",
        portion: ingredientAPI.portion.length > 0 ? ingredientAPI.portion : null,
        portion_name: ingredientAPI.portion.length > 0 ? ingredientAPI.portion[0].modifier : "grammes",
        portion_quantity: ingredientAPI.portion.length > 0 ? ingredientAPI.portion[0].amount : 100,
        portion_gram_weight: ingredientAPI.portion.length > 0 ? ingredientAPI.portion[0].gram_weight : 1
    }
}

const IngredientCard: React.FC<{ _ingredient: Ingredient, handleIngredientClick: (ingredient: Ingredient) => void; }> = ({ _ingredient, handleIngredientClick }) => {
    // const [cardOpened, setCardOpened] = useState(true);
    const [ingredient, setIngredient] = useState(_ingredient);
    const [openMacros, setOpenMacros] = useState(false);
    // const [loaded, setLoaded] = useState(false);

    const changeQuantity = (newQuantity: number) => {
        setIngredient({
            ...ingredient,
            quantity: ingredient.portion_gram_weight * newQuantity, // quantity in gram
            nutriments: recalculateIngredientNutrimentsQuantity(ingredient, ingredient.portion_gram_weight * newQuantity),
            portion_quantity: newQuantity
        }
        )
    };

    const updatePortion = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.selectedOptions[0]; // Get the selected option element

        // Access custom data attributes
        // const portionId = selectedOption.value;
        const portionAmount = selectedOption.getAttribute('data-amount'); // number of portion
        const portionName = selectedOption.getAttribute('data-name');
        const portionWeight = selectedOption.getAttribute('data-weight'); // weight in gram of the selected portion

        setIngredient({
            ...ingredient,
            quantity: Number(portionWeight) * Number(portionAmount),
            nutriments: recalculateIngredientNutrimentsQuantity(ingredient, Number(portionWeight) * Number(portionAmount)),
            portion_name: portionName || "grammes",
            portion_quantity: Number(portionAmount),
            portion_gram_weight: Number(portionWeight)
        })
    };

    useEffect(() => {
        if (ingredient.portion) {
            setIngredient({
                ...ingredient,
                portion_name: ingredient.portion[0].modifier,
                portion_quantity: parseFloat(ingredient.portion[0].amount),
                portion_gram_weight: parseFloat(ingredient.portion[0].gram_weight),
                nutriments: recalculateIngredientNutrimentsQuantity(ingredient, parseFloat(ingredient.portion[0].gram_weight) * parseFloat(ingredient.portion[0].amount)),
                quantity: parseFloat(ingredient.portion[0].gram_weight) * parseFloat(ingredient.portion[0].amount)
            });
        }
        changeQuantity(ingredient.portion_quantity);
    }, []);

    return (
        <div
            className="bg-neutral-50 rounded-xl px-2 w-full transition duration-200 hover:bg-gray-100 shadow-md"
        >
            <div className="flex justify-between mb-4 pt-3">
                <span className='text-sm flex items-center gap-1 w-5/6'>
                    {ingredient.name}
                </span>

                <div className='bg-neutral-800 flex items-center justify-center text-white rounded-full h-6 w-6 uppercase shadow' onClick={() => handleIngredientClick(ingredient)}>
                    {/* Ajouter ingredient */}
                    <PlusIcon className='w-5 h-5 ' />
                    {/* <span className='text-xs'>Ajouter</span> */}
                </div>
            </div>

            <div className='mt-2'>
                <div className="flex items-center gap-2">
                    <input
                        type="number"
                        placeholder="Chercher un ingredient"
                        value={ingredient.portion_quantity}
                        onChange={(e) => changeQuantity(Number(e.target.value))}
                        className="bg-white rounded-full text-center border h-8 px-2 w-24"
                    />
                    <select name="type" id="" className="w-full rounded-full h-8 px-1 border text-center" onChange={(e) => updatePortion(e)}>
                        {
                            ingredient.portion &&
                            ingredient.portion?.map((portion: any) => (
                                <option key={portion.id} value={portion.id} datatype='number' data-amount={portion.amount} data-name={portion.modifier} data-weight={portion.gram_weight}>
                                    {portion.modifier} ({portion.gram_weight}g)
                                </option>
                            ))
                        }
                        <option value={'grammes'} datatype='number' data-amount={100} data-name={'grammes'} data-weight={1} >
                            grammes
                        </option>
                    </select>
                </div>


                <div className='snap-x snap-mandatory flex gap-2 overflow-x-scroll scrollbar-hidden py-4'>
                    {
                        ingredient.data_type === "foundation_food" && (
                            <TooltipComponent
                                tooltipText="Ingédient brut, recommandé"
                                className='snap-start flex-shrink-0 flex items-center bg-green-400 py-1 px-2 w-fit text-xs text-white rounded-full'
                            >
                                {/* <CheckIcon className='w-4 h-4' /> */}
                                <AiOutlineSafetyCertificate className='w-4 h-4' />

                            </TooltipComponent>
                        )
                    }
                    <div className='snap-start flex-shrink-0 flex items-center bg-orange-100 text-orange-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                        <span className='flex items-center gap-2'>
                            Calories
                        </span>
                        <span className='font-bold lowercase'>{ingredient.nutriments.calories}kcal</span>
                    </div>
                    <div className='snap-start flex-shrink-0 flex items-center bg-blue-100 text-blue-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                        <span className='flex items-center gap-2'>
                            Protéines
                        </span>
                        <span className='font-bold lowercase'>{ingredient.nutriments.proteins}g</span>
                    </div>
                    <div className='snap-start flex-shrink-0 flex items-center bg-yellow-100 text-yellow-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                        <span className='flex items-center gap-2'>
                            Lipides
                        </span>
                        <span className='font-bold lowercase'>{ingredient.nutriments.fats}g</span>
                    </div>
                    <div className='snap-start flex-shrink-0 flex items-center bg-green-100 text-green-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                        <span className='flex items-center gap-2'>
                            Glucides
                        </span>
                        <span className='font-bold lowercase'>{ingredient.nutriments.carbohydrates}g</span>
                    </div>
                </div>

            </div>

        </div>
    );
};

// Define the component
const FoodSearch: React.FC<{ addIngredient: (ingredient: Ingredient) => void }> = ({ addIngredient }) => {

    const [query, setQuery] = useState<string>("");  // Store the query string
    const [results, setResults] = useState<Ingredient[]>([]);  // Store the search results
    const [loading, setLoading] = useState<boolean>(false);  // Store loading state
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);  // Store the timeout ID

    // Function to handle the search in Open Food Facts API
    const searchFood = async (searchTerm: string) => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/api/food/search/`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('authToken_access')}`,  // Adjust this if you're using another method for auth
                    'Content-Type': 'application/json'
                },
                params: {
                    search: searchTerm,
                    // search_simple: 1,
                    // action: 'process',
                    // json: 1,
                    // lc: 'fr',  // Only return results in French
                    page_size: 5  // Limit the results to 7 items
                }
            });
            const ingredients: Ingredient[] = response.data.map((ingr: any) => (ingredientParser(ingr)));

            setResults(ingredients);
        } catch (error) {
            console.error("Error fetching food data", error);
        }
        setLoading(false);
    };

    // useEffect to handle debounce
    useEffect(() => {
        // Clear previous timeout if it exists
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // Set a new timeout for 200ms
        const newTimeout = setTimeout(() => {
            if (query.length > 2) {
                searchFood(query);
            } else {
                setResults([]);
            }
        }, 300);

        setDebounceTimeout(newTimeout);

        // Cleanup function to clear timeout when component unmounts or query changes
        return () => {
            if (newTimeout) {
                clearTimeout(newTimeout);
            }
        };
    }, [query]);

    // Handle the input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleIngredientSelected = (ingredient: Ingredient) => {
        setResults([]);
        setQuery("");
        addIngredient(ingredient);

    };

    return (
        <div className="w-full">
            <div className='relative px-4'>

                <input
                    type="text"
                    placeholder="Ajouter un ingredient"
                    value={query}
                    onChange={handleInputChange}
                    className="bg-gray-50 rounded-full border py-2 px-4 w-full"
                />
                <PlusIcon className='w-6 h-6 rotate-45 text-gray-500 absolute right-6 top-1/2 transform -translate-y-1/2' onClick={() => setQuery("")} />

            </div>
            {
                loading ?
                    <div className='flex flex-col items-center justify-center gap-2 py-12'>
                        <SimpleLoading text='Chargement...' />
                    </div>
                    :
                    <>
                        {
                            results.length > 0 ?
                                <div className="flex flex-col gap-2 p-4 overflow-y-scroll">
                                    {results.map((ingredient, index) => (
                                        <IngredientCard
                                            key={index}
                                            _ingredient={ingredient}
                                            handleIngredientClick={handleIngredientSelected} />
                                    ))}
                                    <div className="flex items-center gap-2 py-4">
                                        <AiOutlineSafetyCertificate className='w-5 h-5 text-green-500' />
                                        <span className='text-gray-500 text-xs'>Ingrérdient brut, recommandé</span>
                                    </div>

                                </div>
                                :
                                <>
                                    {
                                        query.length > 2 ?
                                            <div className='flex flex-col items-center justify-center gap-2 py-12'>
                                                <FaRegFaceSadCry className='w-12 h-12 text-gray-200' />
                                                <p className='text-gray-500 text-center text-sm'>Aucun resultat pour "{query}"</p>
                                            </div>

                                            :
                                            <div className='flex flex-col items-center justify-center gap-2 py-12'>
                                                <FaMagnifyingGlass className='w-12 h-12 text-gray-200' />
                                                <p className="text-gray-500 text-center text-sm">Il vous faut au moins 3 lettres pour faire une recherche.</p>
                                            </div>
                                    }
                                </>
                        }
                    </>
            }
        </div>
    );
};

export default FoodSearch;