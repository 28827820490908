// src/pages/Profile.tsx
import React, { useEffect, useState } from 'react';
import EditProfile, { DietInfoCard } from '../components/EditProfile';
import { getBMIReview } from '../services/diet';
import { ArrowLeftStartOnRectangleIcon, ArrowTrendingDownIcon, ArrowTrendingUpIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useUserProvider } from '../provider/UserProvider';
import { calculateAge, round, roundToClosestMultipleOf5 } from '../services/utils';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { MdOutlineCake } from "react-icons/md";
import { LuWeight, LuRuler } from "react-icons/lu";
import { IoMale, IoFemale } from "react-icons/io5";
import { Squircle } from '@squircle-js/react';
import { FaArrowDown, FaArrowUp, FaRunning } from "react-icons/fa";
import { FaUtensils, FaHeartPulse, FaRegCalendarCheck, FaFireFlameCurved, FaGlassWaterDroplet } from "react-icons/fa6";
import { TbTargetArrow } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { log } from 'node:console';

export interface UserProfile {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  display_name: string;
  birthdate: string;
  gender: string;
  height: number;
  weight: number;
  regime: string;
  objective: string;
  gain_per_week: number;
  activity_level: string;
  weight_goal: number;
  meal_schedule: string;
  user_created: boolean;
  profile_created: boolean;
  is_google_user: boolean;
}

const CardProfile: React.FC<{ title: string, stat: string, icon: React.ReactNode, color_bg?: string, className?: string, full?: boolean }> = ({ title, stat, icon, color_bg, className, full }) => {
  return (
    <Squircle
      cornerRadius={15}
      cornerSmoothing={1}
      className={`relative flex flex-col items-center justify-center gap-2 p-4 rounded-lg ${color_bg ? color_bg : 'bg-gray-100'} ${className}`}
    >
      <div className={`absolute h-1 w-full z-10 ${full ? '-left-8 bottom-24' : '-left-4 bottom-16'}`}>
        {/* <Cog6ToothIcon className="h-24 w-24 text-cyan-400" /> */}

        {icon}
      </div>
      <p className="text-4xl text-white font-black z-20">{stat}</p>
      <h3 className="uppercase text-white text-sm text-center z-20">{title}</h3>
    </Squircle >
  );
};

const Profile: React.FC = () => {
  const { profile, dietInfo, handleLogout } = useUserProvider();
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const [openEditProfile, setOpenEditProfile] = useState<boolean>(false);

  useEffect(() => {
    console.log(profile);
    
  }, []);

  return (
    <div className="bg-white md:p-8 overflow-hidden pb-8">

      {/* Open settings */}
      {
        openSettings &&
        <div className="fixed bg-black/50 backdrop-blur-sm w-full h-full z-10" onClick={() => { setOpenSettings(false) }}>
          <div className='fixed w-full bottom-[57px] left-0 bg-white z-20 shadow-lg px-2' onClick={(e) => { e.stopPropagation() }}>
            <p className='relative block text-gray-700 font-bold py-4 border-gray-200'>
              Paramètres
              <span className='absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 rounded-full w-6 h-6 flex items-center justify-center' onClick={() => setOpenSettings(false)}>
                <XMarkIcon className="h-5 w-5 text-white" />
              </span>
            </p>
            <Link to="/dashboard/profile/edit" className='block text-gray-600  py-4 pb-2 border-gray-200' onClick={() => setOpenEditProfile(true)}>
              Modifier mon profil
            </Link>
            <a href="" className='block text-gray-600  py-4 pt-2 border-gray-200'>
              Ajouter une nouvelle pesée
            </a>
            <a onClick={handleLogout} className="block w-full text-red-600  py-4 border-t border-gray-200">
              {/* <ArrowLeftStartOnRectangleIcon className="h-5 w-5" /> */}
              <p>Se déconnecter</p>
            </a>
          </div>
        </div>
      }


      {/* Header */}
      <div className='relative overflow-hidden'>
        <div className='w-16 h-16 rounded-full bg-cyan-400 absolute -top-8 left-4'></div>
        <div className='w-16 h-16 rounded-full bg-purple-400 absolute top-8 right-4'></div>
        <div className="backdrop-blur-2xl flex justify-between items-center py-6 px-4">
          <h1 className="text-2xl font-bold">Bonjour {profile.first_name}</h1>
          <Cog6ToothIcon className="h-8 w-8" onClick={() => setOpenSettings(true)} />
        </div>
      </div>



      <div className="my-4">
        <div className="scroll-px-4 snap-x snap-mandatory scrollbar-hidden flex gap-2 overflow-x-auto">
          <div className="snap-start flex-shrink-0 w-max flex gap-3 items-center bg-neutral-900 rounded-full px-4 pl-1 ml-4 py-1 text-white">
            <div className="text-neutral-900 bg-white rounded-full w-8 h-8 flex items-center justify-center">
              <MdOutlineCake className="h-5 w-5" />
            </div>
            {calculateAge(profile.birthdate) || 'N/A'} ans
          </div>
          <div className="snap-start flex-shrink-0 w-max flex gap-3 items-center bg-neutral-900 rounded-full px-4 pl-1 py-1 text-white">
            <div className="text-neutral-900 bg-white rounded-full w-8 h-8 flex items-center justify-center">
              <LuRuler className="h-5 w-5" />
            </div>
            {profile.height || 'N/A'} cm
          </div>
          <div className="snap-start flex-shrink-0 w-max flex gap-3 items-center bg-neutral-900 rounded-full px-4 pl-1 py-1 text-white">
            <div className="text-neutral-900 bg-white rounded-full w-8 h-8 flex items-center justify-center">
              <LuWeight className="h-5 w-5" />
            </div>
            {profile.weight || 'N/A'} kg
          </div>
          <div className="snap-start flex-shrink-0 w-max flex gap-3 items-center bg-neutral-900 rounded-full px-4 pl-1 py-1 text-white mr-2">
            <div className="text-neutral-900 bg-white rounded-full w-8 h-8 flex items-center justify-center">
              {profile.gender === 'male' ? (
                <IoMale className="h-5 w-5" />
              ) : (
                <IoFemale className="h-5 w-5" />
              )}
            </div>
            {profile.gender === 'male' ? 'Homme' : 'Femme'}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 px-4">
        <div className="w-full flex gap-4">
          <CardProfile
            title="Nombre de Repas par jour"
            stat={profile.meal_schedule || 'N/A'}
            icon={<FaUtensils className="h-24 w-24 text-gray-400" />}
            color_bg='bg-gray-500'
            className='w-1/2'
          />
          <CardProfile
            title="Indice de masse corporelle"
            stat={(dietInfo?.bmi).toString() || 'N/A'}
            icon={<FaHeartPulse className="h-24 w-24 text-orange-400" />}
            color_bg='bg-orange-500'
            className='w-1/2'
          />
        </div>
        <CardProfile
          title="Niveau d'activité physique"
          stat={(profile?.activity_level).toString() || 'N/A'}
          icon={<FaRunning className="h-32 w-32 text-red-400" />}
          color_bg='bg-red-500'
          className='w-full'
          full={true}
        />
      </div>

      <div className='px-4 mt-8'>
        <h4 className='text-2xl font-bold mb-4'>Un aperçu de vos objectifs.</h4>
        <div className="flex flex-wrap gap-4">
          <CardProfile
            title="Votre objetif de poids"
            stat={(profile?.weight_goal).toString() || 'N/A'}
            icon={<TbTargetArrow className="h-32 w-32 text-violet-400" />}
            color_bg='bg-violet-500'
            className='w-full'
            full={true}
          />
          {
            profile?.weight !== profile?.weight_goal &&
            <div className="w-full flex gap-4">
              <CardProfile
                title={profile.weight > profile.weight_goal ? "Kilos à perdre par mois" : "kilos à gagner par mois"}
                stat={(profile.gain_per_week * 4).toString() || 'N/A'}
                icon={profile.weight > profile.weight_goal ? <ArrowTrendingDownIcon className="h-24 w-24 text-green-400" /> : <ArrowTrendingUpIcon className="h-24 w-24 text-green-400" />}
                color_bg='bg-green-500'
                className='w-1/2'
              />
              <CardProfile
                title="Semaines avant l'objectif"
                stat={(dietInfo?.estimatedGoalTimeline).toString() || 'N/A'}
                icon={<FaRegCalendarCheck className="h-24 w-24 text-gray-400" />}
                color_bg='bg-gray-500'
                className='w-1/2'
              />
            </div>
          }
          <div className="w-full flex gap-4">
            <CardProfile
              title="KCAlories pour la journée"
              stat={roundToClosestMultipleOf5(dietInfo?.calorieDailyIntake).toString() || 'N/A'}
              icon={<FaFireFlameCurved className="h-24 w-24 text-orange-400" />}
              color_bg='bg-orange-500'
              className='w-1/2'
            />
            <CardProfile
              title="Litres d'eau par jour"
              stat={round(profile.weight * 0.033, 2).toString() || 'N/A'}
              icon={<FaGlassWaterDroplet className="h-24 w-24 text-blue-400" />}
              color_bg='bg-blue-500'
              className='w-1/2'
            />
          </div>
        </div>
      </div>


      {/* <DietInfoCard
          bmi={dietInfo?.bmi}
          bmiReview={getBMIReview(dietInfo?.bmi)}
          estimatedGoalTimeline={dietInfo?.estimatedGoalTimeline}
          calorieDailyIntake={dietInfo?.calorieDailyIntake}
          proteinIntake={dietInfo?.proteinIntake}
          carbIntake={dietInfo?.carbIntake}
          fatIntake={dietInfo?.fatIntake}
        /> */}

    </div >
  );
};

export default Profile;
