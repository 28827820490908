import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { calculateAge } from '../services/utils';
import { ArrowLeftIcon, ArrowPathIcon, Cog6ToothIcon } from '@heroicons/react/24/solid'
import { useNotification } from '../provider/NotificationProvider';
import apiClient from '../services/apiClient';
import { useUserProvider } from '../provider/UserProvider';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Squircle } from '@squircle-js/react';

export interface ProfileInputFieldProps {
    label: string;
    name: string;
    type: string;
    value: string | number | null;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface ProfileSelectFieldProps {
    label: string;
    name: string;
    value: string | number | null;
    options: { label: string; value: string | number }[];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export interface ProfileRadioFieldProps {
    label: string;
    name: string;
    options: { label: string; value: string }[];
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ProfileInputField: React.FC<ProfileInputFieldProps> = ({ label, name, type, value, onChange }) => {
    return (
        <div className="mb-4">
            <label className="md:w-[400px] block text-gray-700 mb-2">{label}</label>
            <input
                type={type}
                name={name}
                value={value || ''}
                onChange={onChange}
                className="border p-2 w-full rounded-md"
            />
        </div>
    );
};

export const ProfileRadioField: React.FC<ProfileRadioFieldProps> = ({ label, name, options, value, onChange }) => {
    return (
        <div className="mb-4">
            <label className="block mb-2 text-gray-700">{label}</label>
            <div className="flex gap-4">
                {options.map((option) => (
                    <label key={option.value}>
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={value === option.value}
                            onChange={onChange}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
        </div>
    );
};

export const ProfileSelectField: React.FC<ProfileSelectFieldProps> = ({ label, name, value, options, onChange }) => {
    return (
        <div className="mb-4">
            <label className="block mb-2 text-gray-700 md:w-[400px]">{label}</label>
            <select name={name} value={value || ''} onChange={onChange} className="border rounded-md p-2 w-full">
                <option value="" className='w-full'>Choisir</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value} className='w-full'>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};



interface DietInfoCardProps {
    bmi: number;
    bmiReview: string;
    estimatedGoalTimeline: number;
    calorieDailyIntake: number;
    proteinIntake: number;
    carbIntake: number;
    fatIntake: number;
}
export const DietInfoCard: React.FC<DietInfoCardProps> = ({
    bmi,
    bmiReview,
    estimatedGoalTimeline,
    calorieDailyIntake,
    proteinIntake,
    carbIntake,
    fatIntake,
}) => {
    return (
        <div className="flex flex-col gap-9 border-gray-300">

            {/* BMI Section */}
            <div className="flex flex-col gap-2 text-gray-700">
                <p className="font-bold">
                    Votre indice de masse corporelle
                </p>
                <div className="flex flex-col items-center gap-4 w-full">
                    {/* BMI line */}
                    <div className="relative w-full h-4 rounded-lg bg-gray-200">
                        {/* Gradient for simplified BMI ranges */}
                        <div
                            className="absolute inset-0 rounded-lg"
                            style={{
                                background: `linear-gradient(to right, 
                                            #3b82f6 0%,       /* Blue: Below 18.5 */
                                            #3b82f6 36%,    /* End of Underweight */
                                            #10b981 36%,    /* Green: Normal */
                                            #10b981 50%,    /* End of Normal */
                                            #f59e0b 50%,    /* Yellow: Overweight */
                                            #f59e0b 70%,    /* End of Overweight */
                                            #ef4444 70%,    /* Red: Obesity */
                                            #ef4444 80%,      /* End of Obesity */
                                            #b91c1c 80%,      /* Dark Red: Greater than 40 */
                                            #b91c1c 100%)`    /* Extend Dark Red */
                            }}
                        ></div>

                        {/* BMI indicator */}
                        <div
                            className={`absolute -top-1 w-1 h-6 rounded-full border-2 border-nutrimmy-dark shadow-lg`}
                            style={{ left: `${(bmi * 2 / 100) * 100}%` }}
                        >
                            <span className='absolute -bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                {bmi}
                            </span>
                        </div>
                    </div>
                </div>
                {/* <div className="flex gap-2">
                    <span className="w-fit bg-rose-100 text-rose-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {bmi}
                    </span>
                    <span className="w-fit bg-rose-100 text-rose-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {bmiReview}
                    </span>
                </div> */}
            </div>

            {/* Goal Timeline Section */}
            {
                estimatedGoalTimeline > 0 ?
                    <div className="flex flex-col gap-2 text-gray-700">
                        <p className="font-bold">
                            Vous atteindrez votre objectif dans
                        </p>
                        <div className="flex gap-2">
                            <span className="w-fit bg-rose-100 text-rose-500 font-bold px-3 py-1 rounded-lg text-sm">
                                {estimatedGoalTimeline} semaines
                            </span>
                        </div>
                    </div>
                    :
                    <div className="flex flex-col gap-2 text-gray-700">
                        <p className="font-bold">
                            Vous avez déjà atteint votre objectif, bravo!
                        </p>
                    </div>
            }

            {/* Caloric and Macronutrient Intake Section */}
            <div className="flex flex-col gap-2 text-gray-700">
                <p className="font-bold">
                    Cette semaine il vous faudra
                </p>
                <div className="flex gap-2">
                    <span className="w-fit bg-indigo-100 text-indigo-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {calorieDailyIntake} kcal / jour
                    </span>
                </div>
                {/* <p className="font-bold">
                    ...ce qui peut correspondre à
                </p>
                <div className="flex flex-wrap gap-2">
                    <span className="w-fit bg-red-100 text-red-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {proteinIntake}g de protéines / jour
                    </span>
                    <span className="w-fit bg-yellow-100 text-yellow-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {carbIntake}g de glucides / jour
                    </span>
                    <span className="w-fit bg-green-100 text-green-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {fatIntake}g de lipides / jour
                    </span>
                    <span className="w-fit bg-gray-100 text-gray-500 font-bold px-3 py-1 rounded-lg text-sm">
                        + d'autres nutriments
                    </span>
                </div> */}
            </div>
        </div>
    );
};


const EditProfile: React.FC = () => {
    const { addNotification } = useNotification();
    const { profile, setProfile } = useUserProvider();
    const [isFormEdited, setIsFormEdited] = useState(false);

    // Validation Schema
    const validationSchema = Yup.object().shape({
        weight: Yup.number()
            .required('Veuillez entrer votre poids')
            .min(10, 'Le poids doit être supérieur à 10 kg')
            .max(500, 'Le poids doit être inférieur à 500 kg'),
        weight_goal: Yup.number()
            .required('Veuillez entrer votre objectif de poids')
            .min(10, 'Le poids cible doit être supérieur à 10 kg')
            .max(500, 'Le poids cible doit être inférieur à 500 kg'),
        gain_per_week: Yup.number()
            .test(
                'required-gain-per-week',
                'Veuillez sélectionner votre gain par mois',
                function (value) {
                    const { weight, weight_goal } = this.parent;
                    if (weight && weight_goal && weight !== weight_goal) {
                        return value !== undefined;
                    }
                    return true; // No need to validate if weight and weight_goal are equal
                }
            )
            .oneOf(
                [-1, -0.75, -0.5, -0.25, 0.25, 0.5, 0.75, 1],
                'Valeur invalide pour le gain par mois'
            ),
        activity_level: Yup.string()
            .required('Veuillez sélectionner votre activité physique')
            .oneOf(
                ['sedentary', 'light', 'moderate', 'very_active', 'extremely_active'],
                'Valeur invalide pour le niveau d’activité'
            ),
        meal_schedule: Yup.number()
            .required('Veuillez sélectionner le nombre de repas par jour')
            .oneOf([3, 4, 5, 6], 'Valeur invalide pour le nombre de repas'),
    });

    const formik = useFormik({
        initialValues: {
            weight: profile?.weight || '',
            weight_goal: profile?.weight_goal || '',
            gain_per_week: profile?.gain_per_week || '',
            activity_level: profile?.activity_level || '',
            meal_schedule: profile?.meal_schedule || '',
        },
        validationSchema,
        onSubmit: async (values) => {
            const authTokenAccess = Cookies.get('authToken_access');

            if (!authTokenAccess) {
                addNotification('error', 'No authentication token found.');
                return;
            }

            try {
                const response = await apiClient.put('/api/profile/', values, {
                    headers: {
                        Authorization: `Bearer ${authTokenAccess}`,
                        'Content-Type': 'application/json',
                    },
                });
                setProfile({
                    ...profile,
                    ...values,
                    weight: Number(values.weight),
                    weight_goal: Number(values.weight_goal),
                    gain_per_week: Number(values.gain_per_week)
                });
                addNotification('success', 'Profil mis à jour avec succès!');
                setIsFormEdited(false)
            } catch (error: any) {
                addNotification('error', error.message || 'Une erreur est survenue');
            }
        },
    });

    if (!profile) {
        return <div>Loading profile...</div>;
    }

    return (
        <div className='bg-[#FDFBFF]'>
            {/* Header */}
            <div className='relative overflow-hidden'>
                <div className='w-16 h-16 rounded-full bg-cyan-400 absolute -top-8 left-2'></div>
                <div className='w-16 h-16 rounded-full bg-purple-400 absolute top-8 right-2'></div>
                <div className="backdrop-blur-2xl py-6 px-2">
                    <h1 className="text-center font-semibold">Modifier mes informations</h1>
                    <Link to="/dashboard/profile" className="absolute top-1/2 left-4 transform -translate-y-1/2">
                        <ArrowLeftIcon className="h-6 w-6" />
                    </Link>
                </div>
            </div>

            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 p-2 py-4">
                <div>
                    <Squircle
                        cornerRadius={15}
                        cornerSmoothing={1}
                        className={`relative flex bg-white border items-center justify-between gap-2 p-4`}
                    >
                        <label className="block text-violet-950 w-max text-sm uppercase">Votre poids actuel (kg)</label>
                        <input
                            type="number"
                            name="weight"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.weight}
                            placeholder="Entrez votre poids"
                            className={`font-black text-3xl w-16 text-right transition duration-200 ${formik.touched.weight && formik.errors.weight ? 'border-red-500' : ''}`}
                        />
                    </Squircle>
                    {formik.touched.weight && formik.errors.weight && (
                        <p className="text-red-500 text-sm">{formik.errors.weight}</p>
                    )}
                </div>

                <div>
                    <Squircle
                        cornerRadius={15}
                        cornerSmoothing={1}
                        className={`relative flex bg-white border items-center justify-between gap-2 p-4`}
                    >
                        <label className="block text-violet-950 w-max text-sm uppercase">Votre poids à atteindre (kg)</label>
                        <input
                            type="number"
                            name="weight_goal"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.weight_goal}
                            placeholder="Entrez votre poids"
                            className={`font-black text-3xl w-16 text-right transition duration-200 ${formik.touched.weight && formik.errors.weight ? 'border-red-500' : ''}`}
                        />
                    </Squircle>
                    {formik.touched.weight_goal && formik.errors.weight_goal && (
                        <p className="text-red-500 text-sm">{formik.errors.weight_goal}</p>
                    )}
                </div>

                {/* <div>
                    <label className="block font-medium text-gray-700">Votre poids à atteindre (kg)</label>
                    <input
                        type="number"
                        name="weight_goal"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.weight_goal}
                        placeholder="Entrez votre poids cible"
                        className={`p-2 border-b border-rose-300 w-full focus:outline-none focus:border-rose-500 transition duration-200 ${formik.touched.weight_goal && formik.errors.weight_goal ? 'border-red-500' : ''
                            }`}
                    />
                    {formik.touched.weight_goal && formik.errors.weight_goal && (
                        <p className="text-red-500 text-sm">{formik.errors.weight_goal}</p>
                    )}
                </div> */}




                {formik.values.weight_goal && formik.values.weight && formik.values.weight_goal !== formik.values.weight && (
                    <div>
                        <Squircle
                            cornerRadius={15}
                            cornerSmoothing={1}
                            className={`relative flex flex-col bg-white border justify-between gap-2 p-4`}
                        >
                            <label className="block text-violet-950 w-max text-sm uppercase">
                                Nombre de kg à {formik.values.weight_goal > formik.values.weight ? 'gagner' : 'perdre'} par mois
                            </label>
                            <select
                                name="gain_per_week"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.gain_per_week}
                                className={`font-bold w-full transition duration-200 ${formik.touched.gain_per_week && formik.errors.gain_per_week ? 'border-red-500' : ''
                                    }`}
                            >
                                {(formik.values.weight_goal > formik.values.weight
                                    ? [
                                        { label: '1 kg / mois', value: 0.25 },
                                        { label: '2 kg / mois', value: 0.5 },
                                        { label: '3 kg / mois', value: 0.75 },
                                        { label: '4 kg / mois', value: 1.0 },
                                    ]
                                    : [
                                        { label: '-1 kg / mois', value: -0.25 },
                                        { label: '-2 kg / mois', value: -0.5 },
                                        { label: '-3 kg / mois', value: -0.75 },
                                        { label: '-4 kg / mois', value: -1.0 },
                                    ]
                                ).map(({ label, value }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </Squircle>
                        {formik.touched.gain_per_week && formik.errors.gain_per_week && (
                            <p className="text-red-500 text-sm">{formik.errors.gain_per_week}</p>
                        )}
                    </div>
                )}





                {/* 
                {formik.values.weight_goal && formik.values.weight && formik.values.weight_goal !== formik.values.weight && (
                    <div>
                        <label className="block font-medium text-gray-700">
                            Nombre de kg à {formik.values.weight_goal > formik.values.weight ? 'gagner' : 'perdre'} par mois
                        </label>
                        <select
                            name="gain_per_week"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.gain_per_week}
                            className={`p-2 border-b border-rose-300 w-full focus:outline-none focus:border-rose-500 transition duration-200 ${formik.touched.gain_per_week && formik.errors.gain_per_week ? 'border-red-500' : ''
                                }`}
                        >
                            {(formik.values.weight_goal > formik.values.weight
                                ? [
                                    { label: '1 kg / mois', value: 0.25 },
                                    { label: '2 kg / mois', value: 0.5 },
                                    { label: '3 kg / mois', value: 0.75 },
                                    { label: '4 kg / mois', value: 1.0 },
                                ]
                                : [
                                    { label: '-1 kg / mois', value: -0.25 },
                                    { label: '-2 kg / mois', value: -0.5 },
                                    { label: '-3 kg / mois', value: -0.75 },
                                    { label: '-4 kg / mois', value: -1.0 },
                                ]
                            ).map(({ label, value }) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                        {formik.touched.gain_per_week && formik.errors.gain_per_week && (
                            <p className="text-red-500 text-sm">{formik.errors.gain_per_week}</p>
                        )}
                    </div>
                )} */}

                <div>
                    <Squircle
                        cornerRadius={15}
                        cornerSmoothing={1}
                        className={`relative flex flex-col bg-white border justify-between gap-2 p-4`}
                    >
                        <label className="block text-violet-950 w-max text-sm uppercase">
                            Activité quotidienne
                        </label>
                        <select
                            name="activity_level"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.activity_level}
                            className={`font-bold w-full transition duration-200 ${formik.touched.activity_level && formik.errors.activity_level ? 'border-red-500' : ''
                                }`}
                        >
                            {[
                                { label: 'Très peu actif (travail de bureau, peu ou pas de sport)', value: 'sedentary' },
                                { label: 'Légèrement actif (marche quotidienne, peu de sport)', value: 'light' },
                                { label: 'Modérément actif (activités régulières, 1-3 séances de sport/semaine)', value: 'moderate' },
                                { label: 'Très actif (travail physique ou 4-5 séances de sport/semaine)', value: 'very_active' },
                                { label: 'Extrêmement actif (sport intense ou travail très physique)', value: 'extremely_active' },
                            ].map(({ label, value }) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </Squircle>
                    {formik.touched.activity_level && formik.errors.activity_level && (
                        <p className="text-red-500 text-sm">{formik.errors.activity_level}</p>
                    )}
                </div>

                {/* <div>
                    <label className="block font-medium text-gray-700">Activité quotidienne</label>
                    <select
                        name="activity_level"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.activity_level}
                        className={`p-2 border-b border-rose-300 w-full focus:outline-none focus:border-rose-500 transition duration-200 ${formik.touched.activity_level && formik.errors.activity_level ? 'border-red-500' : ''
                            }`}
                    >
                        {[
                            { label: 'Très peu actif (travail de bureau, peu ou pas de sport)', value: 'sedentary' },
                            { label: 'Légèrement actif (marche quotidienne, peu de sport)', value: 'light' },
                            { label: 'Modérément actif (activités régulières, 1-3 séances de sport/semaine)', value: 'moderate' },
                            { label: 'Très actif (travail physique ou 4-5 séances de sport/semaine)', value: 'very_active' },
                            { label: 'Extrêmement actif (sport intense ou travail très physique)', value: 'extremely_active' },
                        ].map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                    {formik.touched.activity_level && formik.errors.activity_level && (
                        <p className="text-red-500 text-sm">{formik.errors.activity_level}</p>
                    )}
                </div> */}

                <div>
                    <Squircle
                        cornerRadius={15}
                        cornerSmoothing={1}
                        className={`relative flex flex-col bg-white border justify-between gap-2 p-4`}
                    >
                        <label className="block text-violet-950 w-max text-sm uppercase">
                            Nombre de repas par jour
                        </label>
                        <select
                            name="meal_schedule"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meal_schedule}
                            className={`font-bold w-full transition duration-200 ${formik.touched.meal_schedule && formik.errors.meal_schedule ? 'border-red-500' : ''
                                }`}
                        >
                            {[
                                { label: '3 (petit dej, dejeuner, diner)', value: 3 },
                                { label: '4 (petit dej, dejeuner, goûter, diner)', value: 4 },
                                { label: '5 (petit dej, dejeuner, goûter, diner, colation)', value: 5 },
                                { label: '6 (petit dej, dejeuner, goûter, diner, colation, colation)', value: 6 },
                            ].map(({ label, value }) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                    </Squircle>
                    {formik.touched.meal_schedule && formik.errors.meal_schedule && (
                        <p className="text-red-500 text-sm">{formik.errors.meal_schedule}</p>
                    )}
                </div>

                {/* <div>
                    <label className="block font-medium text-gray-700">Nombre de repas par jour</label>
                    <select
                        name="meal_schedule"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.meal_schedule}
                        className={`p-2 border-b border-rose-300 w-full focus:outline-none focus:border-rose-500 transition duration-200 ${formik.touched.meal_schedule && formik.errors.meal_schedule ? 'border-red-500' : ''
                            }`}
                    >
                        {[
                            { label: '3 (petit dej, dejeuner, diner)', value: 3 },
                            { label: '4 (petit dej, dejeuner, goûter, diner)', value: 4 },
                            { label: '5 (petit dej, dejeuner, goûter, diner, colation)', value: 5 },
                            { label: '6 (petit dej, dejeuner, goûter, diner, colation, colation)', value: 6 },
                        ].map(({ label, value }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                    {formik.touched.meal_schedule && formik.errors.meal_schedule && (
                        <p className="text-red-500 text-sm">{formik.errors.meal_schedule}</p>
                    )}
                </div> */}

                {
                    isFormEdited ? (
                        formik.isSubmitting ? (
                            <p>Envoie de la modif</p>
                        ) : (
                            <button
                                type="submit"
                                className="bg-blue-500 w-full md:w-fit self-end cursor-pointer flex justify-center items-center gap-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                                disabled={!formik.isValid || formik.isSubmitting}
                            >
                                <ArrowPathIcon className="h-5 w-5" />
                                Mettre à jour
                            </button>
                        )
                    ) : (
                        <div>
                            <p>Toutes vos informations sont sauvergadées.</p>
                        </div>
                    )
                }

            </form>

        </div>
    );
};

export default EditProfile;