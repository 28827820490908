// src/pages/Profile.tsx
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import FoodSearch from '../components/FoodSearch';
import { Ingredient, Recipe, RecipeType } from '../types/recipeType';
import { recalculateIngredientNutrimentsQuantity } from '../services/diet';
import { serializeRecipeAPI } from '../services/recipeService';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../provider/NotificationProvider';
import apiClient from '../services/apiClient';
import { RadioButton } from '../components/WeekPlanGenerator';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useRecipeProvider } from '../provider/RecipeProvider';

export const IngredientCard: React.FC<{ ingredient: Ingredient, updateIngredient: (ingredient: Ingredient) => void, removeIngredient: (fdc_id: number) => void }> = ({ ingredient, updateIngredient, removeIngredient }) => {
    // const [ingredient, setIngredient] = useState(_ingredient);

    const changeQuantity = (newQuantity: number) => {
        const recalculatedIngredient = recalculateIngredientNutrimentsQuantity(ingredient, newQuantity * ingredient.portion_gram_weight);
        // setIngredient({ ...ingredient, quantity: newQuantity, nutriments: recalculatedIngredient })
        updateIngredient({
            ...ingredient,
            quantity: newQuantity * ingredient.portion_gram_weight,
            portion_quantity: newQuantity,
            nutriments: recalculatedIngredient
        });
    };

    useEffect(() => {
    }, []);

    return (
        <div
            className="bg-neutral-50 py-2 px-2 md:px-4 w-full rounded-xl mb-2 shadow-md"
        >

            <div className="flex justify-between md:items-center mb-2">
                <h5 className='text-sm w-5/6'>{ingredient.name}</h5>
                <div onClick={() => removeIngredient(ingredient.fdc_id)} className="flex items-center p-1 bg-red-500 rounded-full">
                    <TrashIcon className="h-4 w-4 text-red-50" />
                    {/* <span>Suprimer</span> */}
                </div>
            </div>

            <div className='flex w-fit items-center gap-2 rounded-lg text-sm'>
                <input
                    type="number"
                    value={Number(ingredient.portion_quantity)}
                    onChange={(e) => changeQuantity(Number(e.target.value))}
                    className="border px-1 h-8 w-24 rounded-full text-center"
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => changeQuantity(Number(e.target.value))}
                />
                {ingredient.portion_name}
            </div>

            <div className='snap-x snap-mandatory flex gap-2 overflow-x-scroll scrollbar-hidden py-4 pt-2'>
                <div className='snap-start flex-shrink-0 flex items-center bg-orange-100 text-orange-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                    <span className='flex items-center gap-2'>
                        Calories
                    </span>
                    <span className='font-bold lowercase'>{ingredient.nutriments.calories}kcal</span>
                </div>
                <div className='snap-start flex-shrink-0 flex items-center bg-blue-100 text-blue-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                    <span className='flex items-center gap-2'>
                        Protéines
                    </span>
                    <span className='font-bold lowercase'>{ingredient.nutriments.proteins}g</span>
                </div>
                <div className='snap-start flex-shrink-0 flex items-center bg-yellow-100 text-yellow-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                    <span className='flex items-center gap-2'>
                        Lipides
                    </span>
                    <span className='font-bold lowercase'>{ingredient.nutriments.fats}g</span>
                </div>
                <div className='snap-start flex-shrink-0 flex items-center bg-green-100 text-green-900 py-1 px-2 rounded-full gap-2 w-fit uppercase text-xs'>
                    <span className='flex items-center gap-2'>
                        Glucides
                    </span>
                    <span className='font-bold lowercase'>{ingredient.nutriments.carbohydrates}g</span>
                </div>
            </div>
        </div>
    );
}

const HandleRecipe: React.FC<{ _recipe: Recipe | null, _action: 'create' | 'update' }> = ({ _recipe, _action }) => {
    const [recipe, setRecipe] = useState<Recipe>(_recipe || {
        name: "",
        ingredients: [],
        type: "MEAL",
        is_public: false,
        is_favorite: false,
        last_modified: new Date()
    });
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const { addNotification } = useNotification();
    const { createRecipe, updateRecipe } = useRecipeProvider();
    const changeRecipeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecipe({ ...recipe, name: event.target.value });
    };

    const addIngredient = (ingredient: Ingredient) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: [...prevRecipe.ingredients, ingredient],
        }));
    };

    const updateIngredient = (updatedIngredient: Ingredient) => {
        setRecipe((prevRecipe) => {
            const updatedIngredients = prevRecipe.ingredients.map((ingredient) =>
                ingredient.fdc_id === updatedIngredient.fdc_id
                    ? { ...ingredient, ...updatedIngredient } // Merge the updated ingredient data
                    : ingredient
            );
            return {
                ...prevRecipe,
                ingredients: updatedIngredients,
            };
        });
    };

    const removeIngredient = (fdc_id: number) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: prevRecipe.ingredients.filter((ingredient) => ingredient.fdc_id !== fdc_id),
        }));
    };

    const updateType = (newType: RecipeType) => {
        setRecipe({ ...recipe, type: newType });
    };

    return (
        <div className="flex flex-col gap-4 md:p-8 bg-nutrimmy-bg">
            <div onClick={() => navigate(`/dashboard/recipes/`)} className="flex h-7 items-center bg-gray-100 p-2 rounded-lg w-fit gap-2 px-4 py-1 cursor-pointer transition duration-200 hover:bg-gray-200">
                <ArrowLeftIcon className="h-4 w-4 text-gray-600" />
                <span>Quitter</span>
            </div>
            <div className='flex flex-col-reverse md:flex-row gap-8'>
                <div className='md:w-2/5'>
                    <FoodSearch addIngredient={addIngredient} />
                </div>
                <div className='md:w-3/5 border-gray-200 md:min-h-[500px]'>
                    <div className="">

                        <input
                            type="text"
                            placeholder="Nom de la recette"
                            value={recipe.name}
                            onChange={changeRecipeName}
                            className="font-bold border border-rose-200 rounded-lg py-2 px-4 w-full"
                        />
                        {/* <select name="type" id="" className="md:border-b md:border-l w-48 py-2 px-4 mb-4" onChange={updateType} defaultValue={recipe.type}>
                        <option value="BREAKFAST">Petit dejeuner</option>
                        <option value="MEAL">Repas</option>
                        <option value="SNACK">Collation</option>
                        <option value="DESSERT">Dessert</option>
                        <option value="OTHER">Autre</option>
                    </select> */}
                        <RadioButton radioDisplay={['Petit dejeuner', 'Repas', 'Collation']} radioValues={['BREAKFAST', 'MEAL', 'SNACK']} selectedValue={recipe.type} setSelectedValue={updateType} />
                    </div>
                    {
                        <div className='flex flex-col gap-2 my-4'>
                            {recipe.ingredients.map((ingredient, index) => (
                                <IngredientCard
                                    key={ingredient.fdc_id}
                                    ingredient={ingredient}
                                    updateIngredient={updateIngredient}
                                    removeIngredient={removeIngredient}
                                />
                            ))}
                            {
                                recipe.ingredients.length === 0 &&
                                <p className='text-orange-500'>Vous devez ajouter au moins un ingrédient.</p>
                            }
                        </div>
                    }
                    {
                        recipe.ingredients.length > 0 && _action === 'create' &&
                        <div
                            className={`bg-neutral-900 cursor-pointer text-white font-bold py-2 px-8 rounded-lg w-fit ${saving ? 'opacity-50' : ''}`}
                            onClick={() => { setSaving(true); createRecipe(recipe).finally(() => setSaving(false)) }}
                        >
                            {saving ? "Enregistrement de la recette..." : "Enregistrer la recette"}
                        </div>
                    }
                    {
                        recipe.ingredients.length > 0 && _action === 'update' &&
                        <div
                            className={`bg-green-700 cursor-pointer text-white font-bold py-2 px-8 rounded-lg w-fit ${saving ? 'opacity-50' : ''}`}
                            onClick={() => { setSaving(true); updateRecipe(recipe).finally(() => setSaving(false)) }}
                        >
                            {saving ? "Modification de la recette..." : "Modifier la recette"}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default HandleRecipe;