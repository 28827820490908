// src/App.tsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Landing from './pages/Landing';
import { NotificationProvider } from './provider/NotificationProvider';
import { UserProvider } from './provider/UserProvider';
import Login from './pages/Login';
import CreateProfile from './pages/CreateProfile';
import { FaInstagram } from "react-icons/fa6";
import { EnvelopeIcon } from '@heroicons/react/24/outline';

const App: React.FC = () => {
  // const isInAppBrowser = /instagram|facebook/.test(navigator.userAgent.toLowerCase());
  // const isInAppBrowser = true;

  return (
    <div>

      <div className=''>
        <NotificationProvider>
          <UserProvider>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/create-profile" element={<CreateProfile />} />
              <Route
                path="/dashboard/*"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} /> {/* Redirect unknown paths to Landing */}


            </Routes>
          </UserProvider>
        </NotificationProvider>
      </div>


      <footer id="footer" className="bg-black text-white py-12 text-center">
        <div className="container mx-auto px-6 space-y-6">

          {/* Company Logo */}
          <a href="nutrimmy.com" className="text-xl font-bold text-rose-500 lowercase flex items-center justify-center">
            <svg width="105" height="13" viewBox="0 0 105 13" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 12.7845V0H2.67448L3.82068 2.71385V6.24088V12.7845H0ZM9.36067 12.7845L4.58872 7.14805L1.47096 3.46547L2.67448 0L10.5642 9.31906L9.36067 12.7845ZM9.36067 12.7845L8.4055 9.94751V0H12.2262V12.7845H9.36067Z" fill="white" />
              <path d="M20.1887 13C18.9916 13 17.9473 12.7726 17.0558 12.3177C16.1643 11.8508 15.4702 11.2104 14.9735 10.3964C14.4768 9.58241 14.2285 8.64871 14.2285 7.5953V0H18.0491V7.91851C18.0491 8.32551 18.1447 8.67265 18.3357 8.95994C18.5267 9.24724 18.7814 9.46869 19.0998 9.62431C19.431 9.76796 19.7939 9.83978 20.1887 9.83978C20.5963 9.83978 20.9529 9.76796 21.2585 9.62431C21.5769 9.46869 21.8253 9.25322 22.0036 8.9779C22.1819 8.69061 22.271 8.34346 22.271 7.93646V0H26.1108V7.61326C26.1108 8.66667 25.8624 9.60037 25.3658 10.4144C24.8818 11.2164 24.1941 11.8508 23.3026 12.3177C22.4238 12.7726 21.3859 13 20.1887 13Z" fill="white" />
              <path d="M31.3617 12.7845V0H35.1824V12.7845H31.3617ZM27.2927 3.05249V0H39.2705V3.05249H27.2927Z" fill="white" />
              <path d="M43.8014 7.68508V5.20718H46.113C46.6351 5.20718 47.0299 5.08748 47.2974 4.84807C47.5776 4.60866 47.7177 4.29144 47.7177 3.89641C47.7177 3.50138 47.5776 3.18416 47.2974 2.94475C47.0299 2.70534 46.6351 2.58564 46.113 2.58564H43.8014V0H46.7243C47.6667 0 48.4945 0.161602 49.2077 0.484806C49.9209 0.80801 50.4813 1.25691 50.8888 1.83149C51.2964 2.40608 51.5001 3.07643 51.5001 3.84254C51.5001 4.60865 51.29 5.27901 50.8697 5.85359C50.4622 6.42818 49.8891 6.87707 49.1504 7.20028C48.4117 7.52348 47.5394 7.68508 46.5332 7.68508H43.8014ZM40.7067 12.7845V0H44.5274V12.7845H40.7067ZM48.0424 12.7845L44.3363 7.32597L47.775 6.62569L52.3789 12.7845H48.0424Z" fill="white" />
              <path d="M53.3366 12.7845V0H57.1573V12.7845H53.3366Z" fill="white" />
              <path d="M59.4183 12.7845V0H62.1883L67.2507 6.96685H66.0472L71.0905 0H73.8605V12.7845H70.078V5.8895L70.632 6.03315L67.8621 9.76796H65.4168L62.6659 6.03315L63.2008 5.8895V12.7845H59.4183Z" fill="white" />
              <path d="M76.1152 12.7845V0H78.8852L83.9476 6.96685H82.7441L87.7874 0H90.5574V12.7845H86.7749V5.8895L87.3289 6.03315L84.5589 9.76796H82.1137L79.3628 6.03315L79.8977 5.8895V12.7845H76.1152Z" fill="white" />
              <path d="M96.9384 8.38536L91.7613 0H96.1551L100.071 6.84116H96.7855L100.702 0H105L99.7848 8.38536H96.9384ZM96.499 12.7845V7.07459H100.32V12.7845H96.499Z" fill="white" />
            </svg>
          </a>

          {/* Quick Navigation Links */}
          {/* <div className="flex justify-center space-x-8 text-gray-300 text-sm"> */}
          {/* <p>Nous sommes en version BETA, des erreurs peuvent survenir, merci de nous prévenir si vous avez un problème ou des bugs.</p> */}
          {/* <a href="#features" className="hover:text-white transition duration-200">Fonctionnalités</a>
            <a href="#pricing" className="hover:text-white transition duration-200">Tarification</a>
            <a href="#faq" className="hover:text-white transition duration-200">FAQ</a>
            <a href="#contact" className="hover:text-white transition duration-200">Contact</a> */}
          {/* </div> */}

          {/* Social Media Links */}
          <div className="flex items-center justify-center gap-6 mt-4 font-semibold">
            <a href="mailto:nutrimmy@gmail.com" target="_blank" rel="noopener noreferrer" className="hover:text-rose-500 transition duration-200">
              <EnvelopeIcon className="h-6 w-6" />
            </a>
            <a href="https://www.instagram.com/nutrimmy.app/" target="_blank" rel="noopener noreferrer" className="hover:text-rose-500 transition duration-200">
              <FaInstagram className="h-6 w-6" />
            </a>
          </div>

          {/* Copyright and Disclaimer */}
          <p className="text-gray-400 text-xs mt-8">
            &copy; 2024 Nutrimmy. Tous droits réservés.
          </p>
        </div>
      </footer>

    </div>
  );
};

export default App;