import React, { useState } from 'react';
import { Recipe, Ingredient, RecipeType } from '../types/recipeType';
import FoodSearch from '../components/FoodSearch';
import { RadioButton } from '../components/WeekPlanGenerator';
import { IngredientCard } from '../pages/HandleRecipe';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/24/outline';
import { FaRegFaceSadCry } from "react-icons/fa6";

interface RecipeFormProps {
    recipe: Recipe;
    setRecipe: React.Dispatch<React.SetStateAction<Recipe>>;
    onSubmit: () => void;
    isSaving: boolean;
    submitLabel: string;
}

const RecipeForm: React.FC<RecipeFormProps> = ({ recipe, setRecipe, onSubmit, isSaving, submitLabel }) => {
    const navigate = useNavigate();
    const [openAddFood, setOpenAddFood] = useState(false);

    const addIngredient = (ingredient: Ingredient) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: [...prevRecipe.ingredients, ingredient],
        }));
        setOpenAddFood(false);
    };

    const updateIngredient = (updatedIngredient: Ingredient) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: prevRecipe.ingredients.map((ingredient) =>
                ingredient.fdc_id === updatedIngredient.fdc_id
                    ? { ...ingredient, ...updatedIngredient }
                    : ingredient
            ),
        }));
    };

    const removeIngredient = (fdc_id: number) => {
        setRecipe((prevRecipe) => ({
            ...prevRecipe,
            ingredients: prevRecipe.ingredients.filter((ingredient) => ingredient.fdc_id !== fdc_id),
        }));
    };

    const updateType = (newType: RecipeType) => {
        setRecipe({ ...recipe, type: newType });
    };

    const changeRecipeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecipe({ ...recipe, name: event.target.value });
    };

    return (
        <div className='min-h-screen'>

            {
                openAddFood ? (
                    <div className="relative flex flex-col gap-4 md:p-8 bg-white min-h-screen pt-12">
                        <div
                            className="absolute top-4 left-4 rounded-full flex items-center uppercase text-xs gap-2 cursor-pointer"
                            onClick={() => { setOpenAddFood(false) }}
                        >
                            <div className='p-1 bg-white rounded-full'>
                                <ArrowLeftIcon className="h-4 w-4 text-black" />
                            </div>
                        </div>
                        <FoodSearch addIngredient={addIngredient} />
                    </div>
                ) : (
                    <div className="relative flex flex-col gap-4 md:p-8 bg-white min-h-screen p-4 pt-12">
                        <div className='absolute w-full h-full bottom-0 left-0 overflow-hidden z-0'>
                            <div className='absolute w-full h-full bottom-0 left-0 backdrop-blur-3xl z-10'></div>
                            <div className='w-16 h-[500px] rounded-full absolute rotate-45 -bottom-24 left-0 transform bg-indigo-300'></div>
                            <div className='w-16 h-[500px] rounded-full absolute rotate-45 -bottom-16 left-52 transform bg-purple-300'></div>
                            <div className='w-16 h-[500px] rounded-full absolute rotate-45 -bottom-24 left-64 transform bg-pink-300'></div>
                        </div>
                        <div className="z-10">
                            <div
                                className="absolute top-4 left-4 rounded-full flex items-center uppercase text-xs gap-2 cursor-pointer"
                                onClick={() => { navigate(-1) }}
                            >
                                <div className='p-1 bg-white rounded-full'>
                                    <ArrowLeftIcon className="h-4 w-4 text-black" />
                                </div>
                                {/* Retour */}
                            </div>

                            <div className="md:w-3/5 border-gray-200 md:min-h-[500px]">
                                <div className='pb-4'>
                                    <label htmlFor="recipeName" className="block uppercase text-gray-700 text-xs">Nom de la recette</label>
                                    <input
                                        id="recipeName"
                                        type="text"
                                        placeholder="Entrez le nom de votre recette ici"
                                        value={recipe.name}
                                        onChange={changeRecipeName}
                                        className="font-bold rounded-lg text-xl mb-4 w-full break-all"
                                    />
                                    <RadioButton
                                        radioDisplay={['Petit dejeuner', 'Repas', 'Collation']}
                                        radioValues={['BREAKFAST', 'MEAL', 'SNACK']}
                                        selectedValue={recipe.type}
                                        setSelectedValue={updateType}
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    {recipe.ingredients.map((ingredient) => (
                                        <IngredientCard
                                            key={ingredient.fdc_id}
                                            ingredient={ingredient}
                                            updateIngredient={updateIngredient}
                                            removeIngredient={removeIngredient}
                                        />
                                    ))}
                                    {recipe.ingredients.length === 0 && (
                                        <div className='flex flex-col items-center justify-center gap-2 pb-4'>
                                            <FaRegFaceSadCry className='w-12 h-12 text-gray-200' />
                                            <p className="text-gray-500 text-center text-sm">Vous n'avez pas d'ingrédients dans votre recette...<br />Cliquez sur le + pour ajouter des ingrédients.</p>
                                        </div>
                                    )}
                                    <div className='flex items-center justify-center border-dashed border-2 border-gray-200 rounded-xl py-4 mb-4'>
                                        <PlusIcon className='w-12 h-12 bg-black text-white rounded-full p-2 shadow-lg'
                                            onClick={() => {
                                                setOpenAddFood(true)
                                            }} />
                                    </div>

                                </div>
                                {recipe.ingredients.length > 0 && (
                                    <div
                                        className={`bg-neutral-950 cursor-pointer shadow text-white text-center py-3 rounded-full w-full ${isSaving ? 'opacity-50' : ''
                                            }`}
                                        onClick={onSubmit}
                                    >
                                        {isSaving ? 'Chargement...' : submitLabel}
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                )
            }

        </div>

    );
};

export default RecipeForm;