import React, { useEffect, useState } from 'react';
import { FOOD_CATEGORY_FR } from '../services/foodService';
import { SimpleLoading } from '../components/Loading';
import { useMealPlanning } from '../provider/MealPlanningProvider';
import { RadioButton } from '../components/WeekPlanGenerator';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline';

const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const getDayName = (dateString: string): string => {
    const date = new Date(dateString);
    return dayNames[date.getUTCDay()];
};

type ShoppingListProps = {
    [day: string]: {
        [category: string]: {
            [ingredientName: string]: {
                unit: string;
                quantity: number;
            };
        };
    };
};

type WeeklyShoppingListProps = {
    [category: string]: {
        [ingredientName: string]: {
            unit: string;
            quantity: number;
            days: string[];
        };
    };
};

const ShoppingList: React.FC<{}> = () => {
    const FRENCH_DAYS = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    // const days = getCurrentWeekDays();
    const { mealPlanning, currentWeekOffset, changeWeek } = useMealPlanning();


    const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
    const [shoppingList, setShoppingList] = useState<ShoppingListProps>({});
    const [weeklyShoppingList, setWeeklyShoppingList] = useState<WeeklyShoppingListProps>({});

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        // getLastCachedMealPlanning(days).then((cacheMealPlanning: { [day: string]: { [key in MealType]?: Meal } }) => {
        const newList: ShoppingListProps = {};
        const weeklyList: WeeklyShoppingListProps = {};

        Object.values(mealPlanning).flatMap(dayMeals => Object.values(dayMeals).filter(Boolean)).forEach((meal: any) => {
            const frenchDay = getDayName(meal.date);

            for (const ingredient of meal.ingredients) {
                const category = ingredient.food_details.food_category;

                // Daily list grouping by category
                newList[frenchDay] = newList[frenchDay] || {};
                newList[frenchDay][category] = newList[frenchDay][category] || {};
                newList[frenchDay][category][ingredient.name] = newList[frenchDay][category][ingredient.name] || {
                    unit: ingredient.portion_name,
                    quantity: 0,
                };
                newList[frenchDay][category][ingredient.name].quantity += Number(ingredient.portion_quantity);

                // Weekly list grouping by category
                weeklyList[category] = weeklyList[category] || {};
                weeklyList[category][ingredient.name] = weeklyList[category][ingredient.name] || {
                    unit: ingredient.portion_name,
                    quantity: 0,
                    days: [],
                };
                weeklyList[category][ingredient.name].quantity += Number(ingredient.portion_quantity);
                if (!weeklyList[category][ingredient.name].days.includes(frenchDay)) {
                    weeklyList[category][ingredient.name].days.push(frenchDay);
                }
            }
        });

        setShoppingList(newList);
        setWeeklyShoppingList(weeklyList);
        // }).catch((error) => {
        //     console.error(error);
        // })
        //     .finally(() => {
        //     })
        setLoaded(true);
    }, [mealPlanning]);

    return (
        <div className='p-4 md:p-8 md:py-16 bg-white'>
            <div className='flex items-center gap-2 justify-between mb-8'>
                <div className='w-10'>
                    {
                        currentWeekOffset !== 0 &&
                        <ArrowLeftIcon onClick={() => { changeWeek(0) }} className='w-5 h-5 cursor-pointer' />
                    }
                </div>
                <h2 className="font-semibold text-gray-800 text-xl">{currentWeekOffset === 0 ? 'Semaine en cours' : 'Semaine prochaine'}</h2>
                <div className='w-10 flex justify-end'>
                    {
                        currentWeekOffset !== 1 &&
                        <ArrowRightIcon onClick={() => { changeWeek(1) }} className='w-5 h-5 cursor-pointer' />
                    }
                </div>
            </div>
            <h4 className="text-2xl font-bold text-gray-800 mb-4">Ma liste de course pour {currentWeekOffset === 0 ? 'cette semaine' : 'la semaine prochaine'}</h4>

            <div className="snap-x overflow-x-scroll scrollbar-hide flex items-center gap-2 mb-8">
                <div
                    onClick={() => setSelectedDay(undefined)}
                    className={`snap-start flex-shrink-0 w-max cursor-pointer rounded-full p-3 py-1 ${selectedDay ? 'bg-neutral-800 text-neutral-50' : 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white'}`}
                >
                    Toute la semaine
                </div>
                {FRENCH_DAYS.map((day, index) => (
                    <div
                        key={index}
                        onClick={() => setSelectedDay(day)}
                        className={`snap-start w-fit cursor-pointer rounded-full p-3 py-1 ${selectedDay === day ? ' bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white' : 'bg-neutral-800 text-neutral-50'}`}
                    >
                        <div className="w-full">{day}</div>
                    </div>
                ))}
            </div>

            {
                !loaded && (
                    <SimpleLoading text='Chargement de la liste de course...' />
                )
            }

            {/* Display Shopping List */}
            {selectedDay === undefined ? (
                // Consolidated weekly shopping list by category
                <div className="mb-6">
                    {
                        Object.keys(weeklyShoppingList).length === 0 && (
                            <div className='flex flex-col items-center justify-center gap-2 py-12'>
                                <Bars3CenterLeftIcon className='w-12 h-12 text-gray-200' />
                                <p className="text-gray-500 text-center text-sm">Remplissez votre planning de repas pour voir votre liste de course.</p>
                            </div>
                        )
                    }
                    {Object.keys(weeklyShoppingList).map((category) => (
                        <div key={category} className="mb-4">
                            <h5 className="uppercase font-semibold text-sm bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent mb-1">{FOOD_CATEGORY_FR[String(category)]}</h5>
                            <ul className="bg-neutral-50 rounded-md px-2 md:pl-4">
                                {Object.keys(weeklyShoppingList[category]).map((ingredientName, index) => {
                                    const { unit, quantity, days } = weeklyShoppingList[category][ingredientName];
                                    return (
                                        <li key={ingredientName} className={"flex justify-between mb-1 py-2 border-b" + (index === Object.keys(weeklyShoppingList[category]).length - 1 ? ' border-none' : '')}>
                                            <span className="text-sm md:text-base text-neutral-700 w-2/3 leading-4">{ingredientName}</span>
                                            <span className="font-semibold text-sm text-neutral-600 text-right leading-4 w-max">
                                                {quantity} {unit} <br />
                                                <span className='font-thin text-xs bg-neutral-100 rounded-full px-2'>{days.map(day => day.charAt(0)).join(' ')}</span>
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                // Daily shopping list by category
                <div className="mb-6">
                    {
                        Object.keys(shoppingList[selectedDay] || {}).length === 0 && (
                            <div className='flex flex-col items-center justify-center gap-2 py-12'>
                                <Bars3CenterLeftIcon className='w-12 h-12 text-gray-200' />
                                <p className="text-gray-500 text-center text-sm">Remplissez votre planning de repas pour voir votre liste de course.</p>
                            </div>)
                    }
                    {Object.keys(shoppingList[selectedDay] || {}).map((category) => (
                        <div key={category} className="mb-4">
                            <h5 className="uppercase font-semibold text-sm bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent mb-1">{FOOD_CATEGORY_FR[String(category)]}</h5>
                            <ul className="bg-neutral-50 rounded-md px-2 md:pl-4">
                                {Object.keys(shoppingList[selectedDay][category]).map((ingredientName, index) => {
                                    const { unit, quantity } = shoppingList[selectedDay][category][ingredientName];
                                    return (
                                        <li key={ingredientName} className={"flex justify-between mb-1 py-2 border-b" + (index === Object.keys(shoppingList[selectedDay][category]).length - 1 ? ' border-none' : '')}>
                                            <span className="text-sm md:text-base text-neutral-700 w-2/3 leading-4">{ingredientName}</span>
                                            <span className="font-semibold text-sm text-neutral-600 text-right leading-4 w-max">
                                                {quantity} {unit}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ShoppingList;
