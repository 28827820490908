import React, { useState } from 'react';
import { Route, Routes, useNavigate, Link, Navigate } from 'react-router-dom';
import Planning from '../components/Planning';
import Profile from './Profile';
import Recipes from './Recipes';
import ShoppingList from './ShoppingList';
import { MealPlanningProvider } from '../provider/MealPlanningProvider';
import { ClipboardDocumentListIcon, XMarkIcon, CalendarDateRangeIcon, UserIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { SimpleLoading } from '../components/Loading';
import { useUserProvider } from '../provider/UserProvider';
import { RecipeProvider } from '../provider/RecipeProvider';
import EditProfile from '../components/EditProfile';
import { PiChefHat } from "react-icons/pi";

const Dashboard: React.FC = () => {

  const [currentPage, setCurrentPage] = useState('planning');
  const [isOpen, setIsOpen] = useState(false);

  const { dietInfo, userProviderReady } = useUserProvider();

  // Show loading while profile data is not yet available
  if (!userProviderReady) {
    return <div className="flex h-screen items-center justify-center">
      <SimpleLoading text="On prépare votre profil..." />
    </div>
  }

  return (
    <div className="bg-nutrimmy-bg">

      <nav className='flex justify-around fixed bottom-0 w-full z-50 text-sm py-2 bg-white border-t border-gray-100 md:hidden'>
        <Link to="/dashboard/planning" className={'w-full flex flex-col items-center justify-center text-xs ' + (currentPage === 'planning' ? 'font-bold text-neutral-900' : 'text-neutral-600')} onClick={() => { setCurrentPage('planning'); setIsOpen(false); }}>
          <CalendarDateRangeIcon className="h-6 w-6" onClick={() => setIsOpen(true)} />
          Planning
        </Link>
        {/* <Link to="/dashboard/shopping-list" className={'w-full flex flex-col items-center justify-center text-xs ' + (currentPage === 'shopping-list' ? 'font-bold text-neutral-900' : 'text-neutral-600')} onClick={() => { setCurrentPage('shopping-list'); setIsOpen(false); }}>
          <PiChefHat className="h-6 w-6" onClick={() => setIsOpen(true)} />
          Cuisiner
        </Link> */}
        <Link to="/dashboard/shopping-list" className={'w-full flex flex-col items-center justify-center text-xs ' + (currentPage === 'shopping-list' ? 'font-bold text-neutral-900' : 'text-neutral-600')} onClick={() => { setCurrentPage('shopping-list'); setIsOpen(false); }}>
          <ShoppingCartIcon className="h-6 w-6" onClick={() => setIsOpen(true)} />
          Courses
        </Link>
        <Link to="/dashboard/recipes" className={'w-full flex flex-col items-center justify-center text-xs ' + (currentPage === 'recipes' ? 'font-bold text-neutral-900' : 'text-neutral-600')} onClick={() => { setCurrentPage('recipes'); setIsOpen(false); }}>
          <ClipboardDocumentListIcon className="h-6 w-6" onClick={() => setIsOpen(true)} />
          Recettes
        </Link>
        <Link to="/dashboard/profile" className={'w-full flex flex-col items-center justify-center text-xs ' + (currentPage === 'profile' ? 'font-bold text-neutral-900' : 'text-neutral-600')} onClick={() => { setCurrentPage('profile'); setIsOpen(false); }}>
          <UserIcon className="h-6 w-6" onClick={() => setIsOpen(true)} />
          Profil
        </Link>
      </nav>

      <nav className="hidden md:block bg-white border-b border-gray-100">
        <div className="container mx-auto px-4 flex justify-between items-center py-4">

          {/* Logo */}
          <h1 className="font-bold text-rose-500 lowercase text-2xl">

            <div className="flex items-center gap-2">
              <img
                src={process.env.PUBLIC_URL + '/logo-full-bg-empty.svg'}
                alt="Logo Nutrimmy"
                className="w-6 h-6 pt-1"
              />
              <Link to="/dashboard" onClick={() => setCurrentPage('dash')}>
                Nutrimmy
              </Link>
            </div>
          </h1>

          {/* Desktop Links */}
          <div className="hidden md:flex space-x-8">
            <Link to="/dashboard/planning" className={currentPage === 'planning' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('planning')}>
              Planning
            </Link>
            <Link to="/dashboard/shopping-list" className={currentPage === 'shopping-list' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('shopping-list')}>
              Cuisiner
            </Link>
            <Link to="/dashboard/shopping-list" className={currentPage === 'shopping-list' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('shopping-list')}>
              Liste de courses
            </Link>
            <Link to="/dashboard/recipes" className={currentPage === 'recipes' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('recipes')}>
              Recettes
            </Link>
            <Link to="/dashboard/profile" className={currentPage === 'profile' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('profile')}>
              Profil
            </Link>
          </div>
        </div>
      </nav>
      <RecipeProvider>
        <MealPlanningProvider>
          <Routes>
            <Route path="/planning" element={
              <div className="">
                {/* <ProfileStatistics profile={profile} dietInfo={dietInfo} /> */}
                {dietInfo && <Planning />}
              </div>
            } />
            <Route path="/shopping-list" element={<ShoppingList />} />
            <Route path="/recipes/*" element={<Recipes />} />
            <Route path="/profile/edit" element={<EditProfile />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="*" element={<Navigate to="/dashboard/planning" replace />} /> {/* Handle invalid sub-paths */}
          </Routes>
        </MealPlanningProvider>
      </RecipeProvider>
    </div>
  );
};

export default Dashboard;
