import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { UserProfile } from '../pages/Profile';
import { getUserDietInfo, UserDietInfo } from '../services/diet';
import apiClient from '../services/apiClient';
import { useNotification } from './NotificationProvider';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { CredentialResponse } from '@react-oauth/google';

interface UserContextType {
    profile: UserProfile;
    setProfile: (profile: UserProfile) => void;
    dietInfo: UserDietInfo;
    setDietInfo: (dietInfo: UserDietInfo) => void;
    authToken_access: string;
    setAuthToken_access: (authToken_access: string) => void;
    handleGoogleSuccess: (response: CredentialResponse) => Promise<boolean>;
    handleGoogleFailure: () => void;
    handleEmailLogin: (email: string, password: string, setSubmitting: (isSubmitting: boolean) => void) => Promise<boolean>;
    userProviderReady: boolean;
    handleLogout: () => void;
}

// Create the context
const UserContext = createContext<UserContextType>({
    profile: {} as UserProfile,
    setProfile: () => { },
    dietInfo: {} as UserDietInfo,
    setDietInfo: () => { },
    authToken_access: '',
    setAuthToken_access: () => { },
    handleGoogleSuccess: () => { return Promise.resolve(false) },
    handleGoogleFailure: () => { },
    handleEmailLogin: () => { return Promise.resolve(false) },
    userProviderReady: false,
    handleLogout: () => { },
});

export const useUserProvider = () => useContext(UserContext);


// Context provider component
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [profile, setProfile] = useState<UserProfile>({
        user_created: false,
        profile_created: false
    } as UserProfile); // Initially undefined until data loads
    const [dietInfo, setDietInfo] = useState<UserDietInfo>({} as UserDietInfo); // Initially undefined until data loads
    const [userProviderReady, setUserProviderReady] = useState(false);
    const [authToken_access, setAuthToken_access] = useState(Cookies.get('authToken_access') || '');
    const [authToken_refresh, setAuthToken_refresh] = useState(Cookies.get('authToken_refresh') || '');
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    const addAuthToken = (access: string, refresh: string) => {
        Cookies.set('authToken_access', access, { expires: 14 });
        Cookies.set('authToken_refresh', refresh, { expires: 14 });
        setAuthToken_access(access);
        setAuthToken_refresh(refresh);
    };

    const removeAuthToken = () => {
        Cookies.remove('authToken_access');
        Cookies.remove('authToken_refresh');
        setAuthToken_access('');
        setAuthToken_refresh('');
    };


    // -----------------------------------------------
    // EMAIL LOGIN
    // -----------------------------------------------

    const handleEmailLogin = async (
        email: string,
        password: string,
        setSubmitting: (isSubmitting: boolean) => void
    ): Promise<boolean> => {
        try {
            // Call the API to handle login or signup
            const response = await apiClient.post('/api/login/email', {
                ...profile,
                email: email,
                password: password,
            });

            // Check for successful login
            if (response.status === 200) {
                addAuthToken(response.data.access, response.data.refresh); // Save tokens
                setProfile({
                    ...profile,
                    user_created: true,
                    email: email,
                });
                addNotification('success', 'Connexion réussie');
                return true; // Login/signup successful
            } else if (response.status === 201) {
                // Handle successful signup
                addAuthToken(response.data.access, response.data.refresh); // Save tokens
                setProfile({
                    ...profile,
                    user_created: true,
                    email: email,
                    profile_created: false,
                });
                addNotification('success', 'Inscription réussie');
                return true;
            } else {
                // console.log(response);
                addNotification('error', 'Une erreur inattendue est survenue.');
                return false;
            }
        } catch (error: any) {
            const errorCode = error.response?.data?.error;

            // Handle specific backend errors
            if (errorCode === 'NOT_SIGNED_UP') {
                addNotification(
                    'warning',
                    'Vous n\'avez pas encore de compte. Veuillez vous inscrire pour continuer.'
                );
                setProfile({
                    ...profile,
                    email: email,
                    user_created: false,
                });
            } else if (errorCode === 'EMAIL_ASSOCIATED_WITH_GOOGLE') {
                addNotification(
                    'warning',
                    'Cet email est associé à un compte Google. Veuillez utiliser Google pour vous connecter.'
                );
            } else if (errorCode === 'INVALID_PASSWORD') {
                addNotification('error', 'Mot de passe incorrect. Veuillez réessayer.');
            } else if (errorCode === 'EMAIL_ALREADY_EXISTS') {
                addNotification(
                    'warning',
                    'Cet email est deja utilisé. Vous avez peut-être utilisé Google pour vous connecter.'
                );
            } else {
                addNotification(
                    'error',
                    'Erreur durant la connexion : ' + (error.response?.data?.error || 'Erreur inconnue'),
                    7000
                );
            }

            // Remove tokens in case of error
            removeAuthToken();
            return false;
        } finally {
            // Reset the submitting state
            setSubmitting(false);
        }
    };


    // -----------------------------------------------
    // GOOGLE
    // -----------------------------------------------

    // Define the success handler for Google Login
    const handleGoogleSuccess = async (response: CredentialResponse): Promise<boolean> => {
        if (response.credential) {
            return apiClient.post(`/api/login/google`, {
                token: response.credential,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                console.log(res);
                
                if (res.data.token) {
                    // Set cookies for the access and refresh tokens
                    addAuthToken(res.data.token.access, res.data.token.refresh);
                    return true;
                } else {
                    addNotification('error', 'Failed to retrieve token from backend.');
                }
                return false;
            }).catch((error) => {
                if (error.response.data.error === 'NOT_SIGNED_UP') {
                    addNotification('warning', 'Vous n\'avez pas encore de compte. Veuillez vous inscrire pour continuer.');
                } else {
                    addNotification('error', 'Erreur durant la connexion : ' + error.response.data.error, 7000);
                }
                removeAuthToken();
                return false;
            })
        }
        removeAuthToken();
        addNotification('error', 'Il y a un problème avec la connexion Google, no credential');
        return false;
    };

    // Handle failure
    const handleGoogleFailure = () => {
        addNotification('error', 'Il y a un problème avec la connexion Google');
        removeAuthToken();
    };

    // -----------------------------------------------
    // LOGOUT
    // -----------------------------------------------

    // Use useCallback to memoize the handleLogout function
    const handleLogout = async () => {
        apiClient.post('/api/logout/', {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('authToken_access')}`,
            }
        }).then((response) => {
            // Remove the auth token from cookies
            addNotification('success', 'Deconnexion reussie.');
            navigate('/');
        }).catch((error) => {
            console.error('Erreur lors de la deconnexion : ' + error);
        }).finally(() => {
            setProfile({
                user_created: false,
                profile_created: false
            } as UserProfile);
            removeAuthToken();
        });
    };

    useEffect(() => {
        // Check connexion, load user profile
        setUserProviderReady(false)

        if (!authToken_access) {
            // addNotification('warning', 'Votre session a expiré, veuillez vous reconnecter.');
            // navigate('/');
            setUserProviderReady(true);
            return;
        }

        apiClient.get('/api/profile/', {
            headers: {
                Authorization: `Bearer ${authToken_access}`,
            },
        }).then((response) => {
            // console.log(response.data);
            const data: UserProfile = response.data;
            setProfile({
                ...profile,
                ...data,
                user_created: true,
                profile_created: true
            });
            setDietInfo(getUserDietInfo({
                ...profile,
                ...data,
                user_created: true,
                profile_created: true
            }));
            navigate('/dashboard');
        }).catch((error) => {
            if (error.response.data.type === 'NO_USER_PROFILE') {
                // The user is created but not the profile
                setProfile({
                    ...profile,
                    user_created: true,
                    profile_created: false
                })
                // addNotification('warning', 'Vous n\'avez pas encore de profil, nous allons le créer.');
                navigate('/login/create-profile');
                return;
            }
            addNotification('warning', 'Votre session a expiré, veuillez vous reconnecter.');
            // console.log(error);
            handleLogout();
            navigate('/');
        }).finally(() => {
            setUserProviderReady(true);
        })
    }, [authToken_access]);

    useEffect(() => {
        apiClient.get('/api/profile/check').then((response) => {
            setProfile({
                ...profile,
                user_created: response.data.user_created,
                profile_created: response.data.profile_created
            })
        })
    }, []);

    useEffect(() => {
        // if (userProviderReady) {
        setDietInfo(getUserDietInfo(profile));
        // }
    }, [profile]);

    return (
        <UserContext.Provider value={{
            profile,
            setProfile,
            dietInfo,
            setDietInfo,
            authToken_access,
            setAuthToken_access,
            handleGoogleSuccess,
            handleGoogleFailure,
            handleEmailLogin,
            handleLogout,
            userProviderReady
        }}>
            {children}
        </UserContext.Provider>
    );
};