// src/pages/Profile.tsx
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// import CreateRecipe from './CreateRecipe';
import { Recipe, RECIPE_TYPE_BREAKFAST, RECIPE_TYPE_FR, RECIPE_TYPE_MEAL, RECIPE_TYPE_SNACK } from '../types/recipeType';
import { BeakerIcon, PencilIcon, StarIcon, TrashIcon } from '@heroicons/react/24/solid'
import HandleRecipe from './HandleRecipe';
import { serializeRecipeDetailsFromAPI, updateRecipeFavorite } from '../services/recipeService';
import apiClient from '../services/apiClient';
import TooltipComponent from '../components/Tooltip';
import { RecipePublicCard } from '../components/RecipeCard';
import { SimpleLoading } from '../components/Loading';
import { useRecipeProvider } from '../provider/RecipeProvider';
import CreateRecipe from './CreateRecipe';
import UpdateRecipe from './UpdateRecipe';


export const RecipeCard: React.FC<{ recipe: Recipe, setRecipe: any }> = ({ recipe, setRecipe }) => {
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [validateDeleteRecipe, setValidateDeleteRecipe] = useState<boolean>(false);
    const [openIngredients, setOpenIngredients] = useState<boolean>(false);
    const navigate = useNavigate();

    const { deleteRecipe, handleFavoriteRecipe } = useRecipeProvider();



    return (
        <>
            {
                openEdit && (
                    <div className='fixed top-0 left-0 w-full h-full z-40 bg-[rgba(0,0,0,0.5)] p-4' onClick={() => setOpenEdit(false)}>
                        <div
                            className="absolute z-50 p-2 md:p-0 bg-white bottom-0 left-1/2 bg-gray-900 overflow-y-scroll w-full md:w-11/12 h-[95%] transform -translate-x-1/2 shadow-xl border rounded-t-xl"
                            onClick={(e) => { e.stopPropagation() }}  // Stop the event from bubbling up
                        >
                            <HandleRecipe
                                _recipe={recipe}
                                _action="update"
                            />
                        </div>
                    </div>
                )
            }
            <div className={"relative flex flex-col w-full bg-white rounded-lg shadow-md h-fit "} onMouseEnter={() => setOpenIngredients(true)} onMouseLeave={() => setOpenIngredients(false)}>
                {
                    validateDeleteRecipe && (
                        <div className="absolute z-50 rounded-lg w-full h-full bg-red-900/80 backdrop-blur text-white p-5 flex flex-col justify-center">
                            <span className="font-bold">Voulez-vous vraiment supprimer cette recette ?</span>
                            <div className="flex gap-2 mt-2 ">
                                <button className="p-2 rounded-full font-bold border border-white hover:bg-white hover:text-red-900" onClick={() => setValidateDeleteRecipe(!validateDeleteRecipe)}>Annuler</button>
                                <button className="p-2 rounded-full font-bold border border-white hover:bg-white hover:text-red-900" onClick={() => deleteRecipe(recipe)}>Supprimer</button>
                            </div>
                        </div>
                    )
                }
                <div className='relative'>
                    <img src={process.env.PUBLIC_URL + '/food_bg.jpg'} alt="" className="w-full rounded-t-lg h-16 md:h-28 object-cover" />
                    <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] rounded-t-lg"></div>
                </div>
                <div className='absolute top-1 right-1 flex gap-2 p-1'>
                    <span className='inline-block uppercase text-xs text-rose-700 bg-rose-50 px-2 py-1 rounded-md'>{RECIPE_TYPE_FR[recipe.type]}</span>
                </div>
                <div className="p-2 rounded-b">
                    <div className="flex justify-between items-center">
                        <h3 className="text-lg font-semibold text-gray-800">{recipe.name}</h3>
                        <div className="cursor-pointer" onClick={() => handleFavoriteRecipe(recipe)}>
                            <TooltipComponent tooltipText={recipe.is_favorite ? "Retirer des favoris" : "Ajouter aux favoris"}>
                                <StarIcon className={"h-6 w-6 " + (recipe.is_favorite ? "text-yellow-400" : "text-gray-300")} />
                            </TooltipComponent>
                        </div>
                    </div>
                    <div className={'transform duration-200 ease-in-out absolute w-full bg-white rounded-lg ' + (openIngredients ? 'z-30 relative' : 'hidden')} style={{ top: '100%', left: 0 }}>
                        <div className="text-gray-600">
                            {recipe.ingredients.map((ingredient, index) => (
                                <div key={index} className="flex flex-col border-t py-1">
                                    <span className="font-bold">{ingredient.portion_quantity || Math.round(ingredient.quantity)} {ingredient.portion_name || 'g'}</span>
                                    <span>{ingredient.name}</span>
                                </div>
                            ))}
                        </div>

                        <div className="mt-2 flex justify-end gap-2">
                            <div onClick={() => navigate(`/dashboard/recipes/update/${recipe.id}`)} className='flex justify-center items-center gap-2 border-2 text-gray-900 border-gray-900 rounded-full w-fit px-4 py-1 cursor-pointer transition duration-200 hover:bg-gray-900 hover:text-white'>
                                <PencilIcon className="h-4 w-4" />
                                Modifier
                            </div>
                            <div className='flex justify-center items-center gap-2 border-2 text-red-900 border-red-900 rounded-full w-fit px-4 py-1 cursor-pointer transition duration-200 hover:bg-red-900 hover:text-white' onClick={() => setValidateDeleteRecipe(!validateDeleteRecipe)}>
                                <TrashIcon className="h-4 w-4" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

const Recipes: React.FC = () => {


    const { recipesPublic, setRecipesPublic, recipesPrivate, setRecipesPrivate, recipeProviderReady } = useRecipeProvider();
    // const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    if (!recipeProviderReady) {
        return <div className="flex h-screen items-center justify-center">
        <SimpleLoading text="On prépare vos recettes..." />
      </div>;
    }

    return (
        <div className="md:p-4">
            <Routes>
                <Route
                    path="/"
                    element={
                        <div className='pb-32 px-4 pt-8'>
                            <h2 className="text-2xl font-bold mb-4">Mes recettes</h2>
                            <div className='grid md:grid-cols-3 gap-4 mb-8'>
                                <div
                                    className='cursor-pointer p-2 border border-dashed border-gray-200 rounded-lg hover:bg-gray-100 md:h-[156px] flex flex-col justify-center items-center py-8 font-bold'
                                    onClick={() => navigate('/dashboard/recipes/create')}
                                >
                                    <BeakerIcon className='w-8 h-8' />
                                    Ajouter une recette
                                </div>
                                {recipesPrivate.map((recipe, index) => (
                                    <RecipeCard key={index} recipe={recipe} setRecipe={(r: Recipe) => setRecipesPrivate(recipesPrivate.map((recipe, i) => i === index ? r : recipe))} />
                                ))}
                            </div>
                            <h2 className="text-2xl font-bold mb-4">Les recettes publiques</h2>


                            {
                                recipesPublic.length > 0 && (
                                    <div>
                                        <span className='block font-bold text-gray-800 text-lg mb-2 mt-4'>Les petits dejeuners</span>
                                        <div className='grid md:grid-cols-3 gap-4'>
                                            {recipesPublic.filter((recipe) => recipe.type === RECIPE_TYPE_BREAKFAST).map((recipe, index) => (
                                                <RecipePublicCard key={index} recipe={recipe} setRecipe={(r: Recipe) => setRecipesPublic(recipesPublic.map((recipe, i) => i === index ? r : recipe))} />
                                            ))}
                                        </div>
                                        <span className='block font-bold text-gray-800 text-lg mb-2 mt-4'>Les repas</span>
                                        <div className='grid md:grid-cols-3 gap-4'>
                                            {recipesPublic.filter((recipe) => recipe.type === RECIPE_TYPE_MEAL).map((recipe, index) => (
                                                <RecipePublicCard key={index} recipe={recipe} setRecipe={(r: Recipe) => setRecipesPublic(recipesPublic.map((recipe, i) => i === index ? r : recipe))} />
                                            ))}
                                        </div>
                                        <span className='block font-bold text-gray-800 text-lg mb-2 mt-4'>Les collations</span>
                                        <div className='grid md:grid-cols-3 gap-4'>
                                            {recipesPublic.filter((recipe) => recipe.type === RECIPE_TYPE_SNACK).map((recipe, index) => (
                                                <RecipePublicCard key={index} recipe={recipe} setRecipe={(r: Recipe) => setRecipesPublic(recipesPublic.map((recipe, i) => i === index ? r : recipe))} />
                                            ))}
                                        </div>
                                    </div>

                                )
                            }
                        </div>
                    }
                />
                {/* <Route path="/add" element={<HandleRecipe _recipe={null} _action='create' />} /> */}
                <Route path="/create" element={<CreateRecipe/>} />
                <Route path="/update/:id" element={<UpdateRecipe />} />
            </Routes>
        </div>
    );
};

export default Recipes;