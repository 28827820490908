// src/pages/Landing.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { RectangleGroupIcon, UserIcon, CogIcon, ClipboardDocumentListIcon, CalendarDateRangeIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import apiClient from '../services/apiClient';
import { ShoppingCartIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { TbBulb } from "react-icons/tb";

const Landing: React.FC = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState<string | undefined>(Cookies.get('authToken_access') || undefined);

    const [open, setOpen] = useState(null);

    const toggleAccordion = (index: any) => {
        setOpen(open === index ? null : index);
    };

    useEffect(() => {
        if (token) {
            apiClient.get('/api/profile/', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            }).catch((error) => {
                Cookies.remove('authToken_access');
                Cookies.remove('authToken_refresh');
                setToken(undefined);
            });
        }
    });



    return (
        <div className="">
            {/* Hero Section */}

            <div className='relative'>

                {/* <div className='w-48 h-48 rounded-full bg-rose-600 absolute top-0 left-8'></div> */}
                {/* <div className='w-48 h-48 rounded-full bg-rose-400 absolute bottom-2 right-8'></div> */}

                <section id="hero" className={"w-full h-screen flex flex-col md:flex-row items-center justify-end backdrop-blur-3xl"}
                    style={
                        {
                            backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5)), url(${process.env.PUBLIC_URL + '/food_bg_home.jpg'})`,
                            backgroundSize: 'cover',
                            backgroundPosition: '70% 50%',
                            backgroundRepeat: 'no-repeat',
                        }
                    }
                >
                    {/* Image for Large Screens */}
                    <div className="hidden md:block w-2/5 h-screen p-8 pr-0">
                        <img
                            src={process.env.PUBLIC_URL + '/food_bg_home.jpg'}
                            alt="Meal prep"
                            className="w-full h-full rounded-2xl object-cover object-right"
                        />
                    </div>

                    {/* Text Content */}
                    <div className="absolute w-full h-full md:w-3/5 flex flex-col items-center justify-center md:items-start md:pl-8 space-y-4 md:space-y-6 p-4 z-10">

                        {/* Logo */}
                        {/* <div className="flex flex-col md:flex-row md:gap-2 text-2xl md:text-3xl font-bold lowercase mb-4">
                            Nutrimmy
                        </div> */}
                        <svg width="105" height="13" viewBox="0 0 105 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 12.7845V0H2.67448L3.82068 2.71385V6.24088V12.7845H0ZM9.36067 12.7845L4.58872 7.14805L1.47096 3.46547L2.67448 0L10.5642 9.31906L9.36067 12.7845ZM9.36067 12.7845L8.4055 9.94751V0H12.2262V12.7845H9.36067Z" fill="black" />
                            <path d="M20.1887 13C18.9916 13 17.9473 12.7726 17.0558 12.3177C16.1643 11.8508 15.4702 11.2104 14.9735 10.3964C14.4768 9.58241 14.2285 8.64871 14.2285 7.5953V0H18.0491V7.91851C18.0491 8.32551 18.1447 8.67265 18.3357 8.95994C18.5267 9.24724 18.7814 9.46869 19.0998 9.62431C19.431 9.76796 19.7939 9.83978 20.1887 9.83978C20.5963 9.83978 20.9529 9.76796 21.2585 9.62431C21.5769 9.46869 21.8253 9.25322 22.0036 8.9779C22.1819 8.69061 22.271 8.34346 22.271 7.93646V0H26.1108V7.61326C26.1108 8.66667 25.8624 9.60037 25.3658 10.4144C24.8818 11.2164 24.1941 11.8508 23.3026 12.3177C22.4238 12.7726 21.3859 13 20.1887 13Z" fill="black" />
                            <path d="M31.3617 12.7845V0H35.1824V12.7845H31.3617ZM27.2927 3.05249V0H39.2705V3.05249H27.2927Z" fill="black" />
                            <path d="M43.8014 7.68508V5.20718H46.113C46.6351 5.20718 47.0299 5.08748 47.2974 4.84807C47.5776 4.60866 47.7177 4.29144 47.7177 3.89641C47.7177 3.50138 47.5776 3.18416 47.2974 2.94475C47.0299 2.70534 46.6351 2.58564 46.113 2.58564H43.8014V0H46.7243C47.6667 0 48.4945 0.161602 49.2077 0.484806C49.9209 0.80801 50.4813 1.25691 50.8888 1.83149C51.2964 2.40608 51.5001 3.07643 51.5001 3.84254C51.5001 4.60865 51.29 5.27901 50.8697 5.85359C50.4622 6.42818 49.8891 6.87707 49.1504 7.20028C48.4117 7.52348 47.5394 7.68508 46.5332 7.68508H43.8014ZM40.7067 12.7845V0H44.5274V12.7845H40.7067ZM48.0424 12.7845L44.3363 7.32597L47.775 6.62569L52.3789 12.7845H48.0424Z" fill="black" />
                            <path d="M53.3366 12.7845V0H57.1573V12.7845H53.3366Z" fill="black" />
                            <path d="M59.4183 12.7845V0H62.1883L67.2507 6.96685H66.0472L71.0905 0H73.8605V12.7845H70.078V5.8895L70.632 6.03315L67.8621 9.76796H65.4168L62.6659 6.03315L63.2008 5.8895V12.7845H59.4183Z" fill="black" />
                            <path d="M76.1152 12.7845V0H78.8852L83.9476 6.96685H82.7441L87.7874 0H90.5574V12.7845H86.7749V5.8895L87.3289 6.03315L84.5589 9.76796H82.1137L79.3628 6.03315L79.8977 5.8895V12.7845H76.1152Z" fill="black" />
                            <path d="M96.9384 8.38536L91.7613 0H96.1551L100.071 6.84116H96.7855L100.702 0H105L99.7848 8.38536H96.9384ZM96.499 12.7845V7.07459H100.32V12.7845H96.499Z" fill="black" />
                        </svg>



                        {/* Main Heading */}
                        <h1 className="text-3xl md:text-5xl text-gray-900 font-bold text-center md:max-w-[800px]">
                            Fini les calculs, planifiez votre semaine de repas en quelques clics.
                        </h1>

                        {/* Subheading */}
                        <p className=" md:text-xl text-gray-800 text-center md:max-w-[650px]">
                            Générez votre semaine de repas personnalisée, ajustée à vos objectifs, en seulement 2 minutes. Selectionez vos recettes préférées et laissez Nutrimmy s’occuper du reste.
                        </p>

                    </div>

                    <div id="cta" className='w-full md:w-2/5 flex flex-col items-center justify-center gap-4 p-4 z-20'>
                        {/* Call-to-Action Button */}
                        <div
                            className="w-full md:w-3/5 flex items-center justify-center bg-nutrimmy-dark shadow-lg cursor-pointer transition duration-200 hover:bg-nutrimmy-bg hover:text-nutrimmy-dark border-nutrimmy-dark border-2 text-white py-3 md:py-2 px-6 md:px-8 rounded-full"
                            onClick={() => navigate('/login')}
                        >
                            Se connecter / S'inscrire
                        </div>
                        <a
                            href="#discover"
                            className="w-full md:w-3/5 flex items-center justify-center text-black shadow-lg cursor-pointer border-nutrimmy-dark border-2 py-3 md:py-2 px-6 md:px-8 rounded-full"
                        >
                            Découvrir Nutrimmy
                        </a>
                        <a
                            href="#footer"
                            className="w-full md:w-3/5 flex items-center justify-center text-black cursor-pointer py-3 md:py-2 px-6 md:px-8 rounded-full"
                        >
                            Nous contacter
                        </a>
                    </div>
                </section>
            </div>

            {/* Discover Section */}
            <section id="discover" className="py-16 md:py-32">
                <div className="container mx-auto md:px-0">
                    <h2 className="text-2xl font-bold mb-8 text-gray-900 leading-7 px-4">
                        <span className="bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent uppercase text-sm">Tout en un</span> <br /> Découvrez les possibilités de Nutrimmy, votre assistant meal prep de tous les jours.
                    </h2>
                    <div className="flex snap-x overflow-x-scroll scrollbar-hide pb-6">
                        <div className="snap-start">
                            <div className="h-fit relative overflow-hidden shadow-lg ml-4 flex-shrink-0 w-[320px] p-4 md:p-8 rounded-2xl bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500">
                                <TbBulb className="h-32 w-32 md:w-12 text-white mx-auto mb-4 absolute -bottom-10 left-1/2 transform -translate-x-1/2" />
                                <h3 className="text-white text-xl font-semibold mb-2">Optimisation intelligente des recettes</h3>
                                <p className="text-sm text-white leading-5 pb-24">Transformez n'importe quelle recette pour qu'elle corresponde à vos besoins caloriques sans perdre les proportions d'ingrédients d'origine.</p>
                            </div>
                        </div>
                        <div className="snap-start">
                            <div className="h-fit relative overflow-hidden shadow-lg ml-4 flex-shrink-0 w-[300px] p-4 md:p-8 rounded-2xl bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100">
                                <ClipboardDocumentCheckIcon className="h-32 w-32 md:w-12 text-purple-200 mx-auto mb-4 absolute -bottom-10 left-1/2 transform -translate-x-1/2" />
                                <h3 className="text-black text-xl font-semibold mb-2">Planification de repas personnalisée</h3>
                                <p className="text-sm text-black leading-5 pb-24">Créez des plannings de repas sur mesure en fonction de vos objectifs, pour une semaine de repas équilibrés et adaptés.</p>
                            </div>
                        </div>
                        <div className="snap-start">
                            <div className="h-fit relative overflow-hidden shadow-lg ml-4 mr-4 flex-shrink-0 w-[300px] p-4 md:p-8 rounded-2xl bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100">
                                <ShoppingCartIcon className="h-32 w-32 md:w-12 text-purple-200 mx-auto mb-4 absolute -bottom-10 left-1/2 transform -translate-x-1/2" />
                                <h3 className="text-black text-xl font-semibold mb-2">Liste de courses automatisée</h3>
                                <p className="text-sm text-black leading-5 pb-24">Générez automatiquement une liste de courses pour tous les ingrédients nécessaires à vos repas de la semaine.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Steps Section */}
            <section className="bg-white mx-auto px-4 md:px-6 py-16 md:py-32 bg-gradient-to-br from-indigo-500 to-purple-500">
                {/* <h2 className="text-2xl md:text-4xl font-semibold mb-16 md:mb-24">Comment obtenir son planning sur mesure en 2 minutes?</h2> */}
                <h2 className="text-2xl font-bold mb-8 text-gray-50 leading-7">
                    <span className="bg-gradient-to-br from-indigo-100 to-pink-100 bg-clip-text text-transparent uppercase text-sm">Simple et rapide</span> <br /> Obtenez votre planning de repas pour la semaine, sur mesure, en deux minutes.
                </h2>

                <div className="space-y-4 md:space-y-12 max-w-4xl mx-auto">

                    {/* Each Step */}
                    <div className="flex flex-col relative md:flex-row items-center overflow-hidden md:items-start bg-white rounded-lg p-4 pb-8 md:pb-12 text-left">
                        <div className="text-purple-200 absolute -right-8 -bottom-8 flex flex-row items-center md:mb-0">
                            <UserIcon className="mx-auto w-32 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5 z-20">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 1 : Créez votre compte et profil nutritionnel</h3>
                            <p className="text-sm text-gray-700">Inscrivez-vous et entrez des informations essentielles comme votre poids, taille, et objectifs. Cela permettra à Nutrimmy de personnaliser vos repas selon vos besoins.</p>
                        </div>
                    </div>

                    {/* Additional Steps - Repeat Structure */}
                    <div className="flex flex-col relative md:flex-row items-center overflow-hidden md:items-start bg-white rounded-lg p-4 pb-8 md:pb-12 text-left">
                        <div className="text-purple-200 absolute -right-8 -bottom-8 flex flex-row items-center md:mb-0">
                            <RectangleGroupIcon className="mx-auto w-32 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5 z-20">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 2 : Accédez à votre Dashboard</h3>
                            <p className="text-sm text-gray-700">Dès votre connexion, vous arrivez sur votre Dashboard. Accédez à votre profil pour modifier vos informations, définir le nombre de repas par jour, accéder à votre liste de courses et à vos recettes.</p>
                        </div>
                    </div>

                    <div className="flex flex-col relative md:flex-row items-center overflow-hidden md:items-start bg-white rounded-lg p-4 pb-8 md:pb-12 text-left">
                        <div className="text-purple-200 absolute -right-8 -bottom-8 flex flex-row items-center md:mb-0">
                            <ClipboardDocumentListIcon className="mx-auto w-32 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5 z-20">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 3 : Ajouter des recettes</h3>
                            <p className="text-sm text-gray-700">Explorez des recettes publiques ou créez vos propres recettes. Ajoutez vos favorites pour les retrouver facilement dans vos plannings.</p>
                        </div>
                    </div>

                    <div className="flex flex-col relative md:flex-row items-center overflow-hidden md:items-start bg-white rounded-lg p-4 pb-8 md:pb-12 text-left">
                        <div className="text-purple-200 absolute -right-8 -bottom-8 flex flex-row items-center md:mb-0">
                            <CogIcon className="mx-auto w-32 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5 z-20">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 4 : Optimiser et générer la semaine</h3>
                            <p className="text-sm text-gray-700">Depuis le Planning, descendez jusqu'à la section « Générer ma semaine » et laissez Nutrimmy configurer automatiquement vos repas pour une semaine complète.</p>
                        </div>
                    </div>

                    <div className="flex flex-col relative md:flex-row items-center overflow-hidden md:items-start bg-white rounded-lg p-4 pb-8 md:pb-12 text-left">
                        <div className="text-purple-200 absolute -right-8 -bottom-8 flex flex-row items-center md:mb-0">
                            <CalendarDateRangeIcon className="mx-auto w-32 md:w-24" />
                        </div>
                        <div className="w-full md:w-4/5 z-20">
                            <h3 className="md:text-2xl font-semibold mb-2 md:mb-4">Étape 5 : Profitez de votre planning personnalisé</h3>
                            <p className="text-sm text-gray-700">Votre planning de repas est maintenant prêt ! Profitez d'une semaine organisée de repas parfaitement adaptés à vos besoins nutritionnels.</p>
                        </div>
                    </div>
                </div>
            </section>




            {/* Discover Section */}
            <section id="prices" className="py-16 md:py-32">
                <div className="container mx-auto md:px-0">
                    <h2 className="text-2xl font-bold mb-8 text-gray-900 leading-7 px-4">
                        <span className="bg-gradient-to-bl from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent uppercase text-sm">Tarifs</span> <br /> Nutrimmy est entièrement gratuit pour le moment.
                    </h2>
                    {/* <div className="flex snap-x overflow-x-scroll scrollbar-hide pb-6">
                        <div className="snap-start">
                            <div className="h-fit relative overflow-hidden shadow-lg ml-4 flex-shrink-0 w-[320px] p-4 md:p-8 rounded-2xl bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500">
                                <TbBulb className="h-32 w-32 md:w-12 text-white mx-auto mb-4 absolute -bottom-10 left-1/2 transform -translate-x-1/2" />
                                <h3 className="text-white text-xl font-semibold mb-2">Optimisation intelligente des recettes</h3>
                                <p className="text-white leading-5 pb-24">Transformez n'importe quelle recette pour qu'elle corresponde à vos besoins caloriques sans perdre les proportions d'ingrédients d'origine.</p>
                            </div>
                        </div>
                        <div className="snap-start">
                            <div className="h-fit relative overflow-hidden shadow-lg ml-4 flex-shrink-0 w-[300px] p-4 md:p-8 rounded-2xl bg-gradient-to-br from-indigo-500 to-neutral-950">
                                <ClipboardDocumentCheckIcon className="h-32 w-32 md:w-12 text-purple-200 mx-auto mb-4 absolute -bottom-10 left-1/2 transform -translate-x-1/2" />
                                <h3 className="text-black text-xl font-semibold mb-2">Planification de repas personnalisée</h3>
                                <p className="text-black leading-5 pb-24">Créez des plannings de repas sur mesure en fonction de vos objectifs, pour une semaine de repas équilibrés et adaptés.</p>
                            </div>
                        </div>
                        <div className="snap-start">
                            <div className="h-fit relative overflow-hidden shadow-lg ml-4 mr-4 flex-shrink-0 w-[300px] p-4 md:p-8 rounded-2xl bg-gradient-to-br from-indigo-500 to-neutral-950">
                                <ShoppingCartIcon className="h-32 w-32 md:w-12 text-purple-200 mx-auto mb-4 absolute -bottom-10 left-1/2 transform -translate-x-1/2" />
                                <h3 className="text-black text-xl font-semibold mb-2">Liste de courses automatisée</h3>
                                <p className="text-black leading-5 pb-24">Générez automatiquement une liste de courses pour tous les ingrédients nécessaires à vos repas de la semaine.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>








            {/* FAQ Section */}
            <section className="relative container mx-auto px-4 md:px-4 md:px-6 py-16 md:pb-32 bg-black">

                <div className='absolute w-full h-full bottom-0 left-0 overflow-hidden z-0'>
                    <div className='absolute w-full h-full bottom-0 left-0 backdrop-blur-3xl z-10'></div>
                    <div className='w-16 h-[500px] rounded-full absolute rotate-45 -top-24 left-0 transform bg-indigo-300'></div>
                    <div className='w-16 h-[300px] rounded-full absolute rotate-45 -top-16 left-52 transform bg-purple-300'></div>
                    <div className='w-16 h-[500px] rounded-full absolute rotate-45 -top-24 left-64 transform bg-pink-300'></div>
                </div>

                <div className="relative z-10">
                    <h2 className="text-2xl md:text-3xl font-semibold mb-8 md:mb-12 text-white ">Questions fréquentes</h2>
                    {/* FAQ Item 1 */}
                    <div className="border-b border-slate-200 ">
                        <button
                            onClick={() => toggleAccordion(1)}
                            className="w-full flex justify-between items-center py-5 text-neutral-50"
                        >
                            <span className='text-left w-5/6'>Nutrimmy est-elle uniquement pour les sportifs ?</span>
                            <ChevronDownIcon
                                className={`h-5 w-5 transition-transform duration-300 ${open === 1 ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${open === 1 ? "max-h-screen" : "max-h-0"
                                }`}
                        >
                            <div className="pb-5 text-sm text-neutral-200">
                                Non, Nutrimmy s'adresse à tous ceux qui veulent planifier leurs
                                repas de manière efficace et précise, mais elle est
                                particulièrement optimisée pour les besoins des sportifs.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 2 */}
                    <div className="border-b border-slate-200 ">
                        <button
                            onClick={() => toggleAccordion(2)}
                            className="w-full flex justify-between items-center py-5 text-neutral-50"
                        >
                            <span className='text-left w-5/6'>Puis-je utiliser mes propres recettes ?</span>
                            <ChevronDownIcon
                                className={`h-5 w-5 transition-transform duration-300 ${open === 2 ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${open === 2 ? "max-h-screen" : "max-h-0"
                                }`}
                        >
                            <div className="pb-5 text-sm text-neutral-200">
                                Oui, vous pouvez ajouter vos propres recettes et les ajuster avec
                                l'optimisateur intelligent.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 3 */}
                    <div className="border-b border-slate-200 ">
                        <button
                            onClick={() => toggleAccordion(3)}
                            className="w-full flex justify-between items-center py-5 text-neutral-50"
                        >
                            <span className='text-left w-5/6'>Comment fonctionne la liste de courses ?</span>
                            <ChevronDownIcon
                                className={`h-5 w-5 transition-transform duration-300 ${open === 3 ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${open === 3 ? "max-h-screen" : "max-h-0"
                                }`}
                        >
                            <div className="pb-5 text-sm text-neutral-200">
                                Nutrimmy génère automatiquement une liste de courses en fonction
                                de votre planning hebdomadaire, regroupant tous les ingrédients
                                nécessaires pour la semaine.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 4 */}
                    <div className="border-b border-slate-200 ">
                        <button
                            onClick={() => toggleAccordion(4)}
                            className="w-full flex justify-between items-center py-5 text-neutral-50"
                        >
                            <span className='text-left w-5/6'>Est-ce que Nutrimmy prend en compte les préférences alimentaires ?</span>
                            <ChevronDownIcon
                                className={`h-5 w-5 transition-transform duration-300 ${open === 4 ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${open === 4 ? "max-h-screen" : "max-h-0"
                                }`}
                        >
                            <div className="pb-5 text-sm text-neutral-200">
                                Actuellement, Nutrimmy ne prend pas en compte les préférences
                                alimentaires, mais vous pouvez créer vos propres recettes et les
                                optimiser en fonction de vos besoins avec l'optimisateur
                                intelligent.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 5 */}
                    <div className="border-b border-slate-200 ">
                        <button
                            onClick={() => toggleAccordion(5)}
                            className="w-full flex justify-between items-center py-5 text-neutral-50"
                        >
                            <span className='text-left w-5/6'>Puis-je modifier les portions des repas ?</span>
                            <ChevronDownIcon
                                className={`h-5 w-5 transition-transform duration-300 ${open === 5 ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${open === 5 ? "max-h-screen" : "max-h-0"
                                }`}
                        >
                            <div className="pb-5 text-sm text-neutral-200">
                                Absolument ! Nutrimmy permet d'ajuster les portions pour chaque
                                repas en fonction de vos besoins caloriques.
                            </div>
                        </div>
                    </div>

                    {/* FAQ Item 6 */}
                    <div>
                        <button
                            onClick={() => toggleAccordion(6)}
                            className="w-full flex justify-between items-center py-5 text-neutral-50"
                        >
                            <span className='text-left w-5/6'>L'application est-elle disponible sur le store de mon mobile ?</span>
                            <ChevronDownIcon
                                className={`h-5 w-5 transition-transform duration-300 ${open === 6 ? "rotate-180" : ""
                                    }`}
                            />
                        </button>
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${open === 6 ? "max-h-screen" : "max-h-0"
                                }`}
                        >
                            <div className="pb-5 text-sm text-neutral-200">
                                Non, Nutrimmy n'est pas encore disponible sous forme d'application.
                            </div>
                        </div>
                    </div>
                </div>


                {/* 

                <div className=" mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">

                    <div className="space-y-4">
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Nutrimmy est-elle uniquement pour les sportifs ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Non, Nutrimmy s'adresse à tous ceux qui veulent planifier leurs repas de manière efficace et précise, mais elle est particulièrement optimisée pour les besoins des sportifs.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Puis-je utiliser mes propres recettes ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Oui, vous pouvez ajouter vos propres recettes et les ajuster avec l'optimisateur intelligent.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Comment fonctionne la liste de courses ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Nutrimmy génère automatiquement une liste de courses en fonction de votre planning hebdomadaire, regroupant tous les ingrédients nécessaires pour la semaine.</p>
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Est-ce que Nutrimmy prend en compte les préférences alimentaires ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Actuellement, Nutrimmy ne prend pas en compte les préférences alimentaires, mais vous pouvez créer vos propres recettes et les optimiser en fonction de vos besoins avec l'optimisateur intelligent.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">Puis-je modifier les portions des repas ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Absolument ! Nutrimmy permet d'ajuster les portions pour chaque repas en fonction de vos besoins caloriques.</p>
                        </div>
                        <div className='bg-white p-2 md:p-4 rounded-lg bg-white border border-rose-100'>
                            <h3 className="font-semibold md:text-xl text-gray-800 mb-2">L'application est-elle disponible sur mobile ?</h3>
                            <p className='text-sm text-gray-600 border-l-2 border-rose-300 pl-2'>Oui, vous pouvez y acceder via votre navigateur sur mobile. Nutrimmy n'est aps encore disponible sous forme d'application.</p>
                        </div>
                    </div>

                </div> */}
            </section>
        </div >
    )
};

export default Landing;
